import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useGetOneByNameCommunity} from '@/modules/community/hooks/useCommunity';
import {useLocation} from 'react-router-dom';
import {communityNavigation} from '@/settings';
import CommunityApiService from '@/modules/community/services/CommunityApiService';

const CommunityContext = createContext();

function CommunityProvider({children}) {
  const storageName = CommunityApiService.getNameSpace();
  const [currentId, setCurrentId] = useState('');
  const [currentName, setCurrentName] = useState(storageName);
  const [insideCommunity, setInsideCommunity] = useState(null);
  const {pathname} = useLocation();
  const {isLoading, data, isError, refetch, clear} = useGetOneByNameCommunity({name: currentName});

  const communityData = useMemo(() => (data && data.data) || {}, [data]);

  const changeCommunity = useCallback((_id, name) => {
    CommunityApiService.setCurrentId(_id);
    CommunityApiService.setNameSpace(name);
    setCurrentName(name);
    setCurrentId(_id);
  }, []);

  const navigationMenu = useMemo(() => currentName ? communityNavigation(currentName) : null, [currentName]);

  useEffect(() => {
    if (!navigationMenu) return;
    const isChild = navigationMenu.some((section) => section.menu.some(item => {
      if (item.isBack) return false;
      return pathname.startsWith(item?.link) || (item.include && item.include.some(e => pathname.startsWith(e)));
    }));
    setInsideCommunity(isChild);
  }, [navigationMenu, pathname]);

  return (
    <CommunityContext.Provider value={{
      currentId,
      currentName,
      isLoading,
      community: communityData,
      isError,
      refetch,
      clear,
      changeCommunity,
      havePromoted: !!communityData.promoted,
      navigationMenu,
      isCommunity: !!communityData._id || !!currentName,
      insideCommunity: !!insideCommunity && (!!communityData._id || !!currentName)
    }}>
      {children}
    </CommunityContext.Provider>
  );
}

function useCommunity() {
  const context = useContext(CommunityContext);
  if (context === undefined) {
    throw new Error('useCommunity must be used within a CommunityProvider');
  }
  const {
    currentId, isLoading, community, isError, refetch, clear, changeCommunity, isCommunity, insideCommunity,
    currentName, navigationMenu, havePromoted
  } = context;
  return {
    currentId,
    isLoading,
    community,
    isError,
    refetch,
    clear,
    changeCommunity,
    isCommunity,
    insideCommunity,
    currentName,
    navigationMenu,
    havePromoted
  };
}

CommunityProvider.propTypes = {
  isMobile: PropTypes.bool,
  children: PropTypes.any
};

export {CommunityProvider, useCommunity};
