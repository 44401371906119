import i18n from 'i18next';
import 'moment/min/locales';
import {ValidatorProvider} from '@dofleini/validator';
import * as ES from '../locales/es';

// the translations
const resources = {
  es: {}
};

Object.keys(ES).forEach(name => {
  resources.es[name] = name === 'common' ? ES[name] : Object.assign({}, ES.common, ES[name]);
});

let lng = localStorage.getItem('i18nextLng');
if (!lng) {
  lng = 'es';
  localStorage.setItem('i18nextLng', lng);
}

const options = {
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  debug: process.env.NODE_ENV !== 'production',
  lng,
  resources,
  fallbackLng: lng,
  ns: ['common'],
  defaultNS: 'common',
  react: {
    wait: false,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'common.json'
  },
};

i18n.on('languageChanged', function (lng) {
  localStorage.setItem('i18nextLng', lng);
  i18n.__currentLng__ = lng;
  if (i18n.languageChanged)
    i18n.languageChanged(lng);
});


i18n.subscribe = (callback) => {
  i18n.languageChanged = callback;
  callback(i18n.__currentLng__);
};

i18n
  .init(options);

ValidatorProvider.In18 = i18n;

export {i18n};
