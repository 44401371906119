export default [
  {value: '#1890ff', render: '/images/colors/blue-color.svg'},
  {value: '#dd0246', render: '/images/colors/red-color.svg'},
  {value: '#000000', render: '/images/colors/black-color.svg'},
  {value: '#021fdd', render: '/images/colors/blue-sky-color.svg'},
  {value: '#979797', render: '/images/colors/gray-color.svg'},
  {value: '#00c977', render: '/images/colors/green-color.svg'},
  {value: '#00b7aa', render: '/images/colors/paste-color.svg'},
  {value: '#dd028d', render: '/images/colors/pink-color.svg'},
  {value: '#9502dd', render: '/images/colors/purple-color.svg'},
  {value: '#d8ca00', render: '/images/colors/yellow-color.svg'}
];
