import React, {memo, useMemo} from 'react';
import FormBuilder from 'antd-form-builder';
import PropTypes from 'prop-types';

export const EntityDetailContent = ({data, fields, viewMode, form}) => {

  const meta = useMemo(() => ({
    initialValues: data,
    columns: 2,
    formItemLayout: null, // Must set this for inline layout
    fields
  }), [data, fields]);

  return (
    <FormBuilder form={form} meta={meta} viewMode={viewMode}/>
  );

};

export default memo(EntityDetailContent);


EntityDetailContent.propTypes = {
  data: PropTypes.object,
  fields: PropTypes.array,
  form: PropTypes.object,
  viewMode: PropTypes.bool
};
