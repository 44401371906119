import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Switch, Tooltip} from 'antd';
import {InfoCircleOutlined} from '@ant-design/icons';
import style from './index.less';
import classNames from 'classnames';

export const BooleanComponent = ({filter, t, applyFilter}) => {
  const onFinish = useCallback((value) => {
    applyFilter({
      value,
      field: filter.field,
      type: 'TERM',
    });
  }, [applyFilter, filter.field]);
  
  return (<div className="w-56">
    <div className="flex py-2">
      <div className={'w-full flex flex-row items-center justify-space'}>
        <div className={'px-2 m-0 w-full flex flex-row items-center justify-center'}>
          <Switch defaultChecked={false} onChange={onFinish}/>
          <span className={'ml-2'}>{t(filter.name)}</span>
        </div>
        {filter.help && <div className={'px-2'}>
          <Tooltip title={t(filter.help)}>
            <InfoCircleOutlined/>
          </Tooltip>
        </div>}
      </div>
    </div>
  </div>);
};

BooleanComponent.propTypes = {
  filter: PropTypes.object,
  t: PropTypes.func,
  applyFilter: PropTypes.func,
};

export const ValueBoolean = ({filterValue, applyFilter, filter}) => {
  const onFinish = useCallback((value) => {
    applyFilter({
      value,
      field: filter.field,
      type: 'TERM',
    });
  }, [applyFilter, filter.field]);
  
  return <div className={classNames('flex flex flex-row items-center', style.switchContainer)}>{
    <Switch checked={filterValue.value} size={'small'} onChange={onFinish}/>
  }</div>;
};

ValueBoolean.propTypes = {
  filter: PropTypes.object,
  t: PropTypes.func,
  filterValue: PropTypes.any,
  applyFilter: PropTypes.func,
};
