import {useMutation} from 'react-query';
import {useCallback} from 'react';

export const useUpload = (service, onSuccess) => {

  const [mutate, {isLoading, isError, error, data}] = useMutation(service, {throwOnError: true});

  const handleUpload = useCallback((files) => {
    if (files.length) {
      const formData = new FormData();
      formData.append('files', files[0]);
      mutate(formData).then(({data}) => {
        onSuccess && onSuccess(data);
      });
    }
  }, [mutate, onSuccess]);

  return {
    handleUpload,
    isLoading,
    isError,
    error,
    data
  };
};
