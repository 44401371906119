import {memo} from 'react';
import PropTypes from 'prop-types';
import {useUser} from '@dofleini/security';

function PermissionCheck({permissions, checkAuthenticated, children }) {
  const {isAuthenticated, hasPermission} = useUser();
  if (checkAuthenticated && !isAuthenticated) return null;
  const isValid = hasPermission(permissions);
  if (!isValid) return null;
  return children;
}

export default memo(PermissionCheck);

PermissionCheck.propTypes = {
  permissions: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  checkAuthenticated: PropTypes.bool
};
