export const CACHE_KEY_LIST_NOTIFICATIONS = 'ms-not';
export const SECURITY_KEY_NOTIFICATIONS = 'NOTIFICATIONS';
export const CACHE_KEY_NOTIFICATIONS = 'ms-not-one';
export const ROUTE_PATH_NOTIFICATIONS = '/notifications';
export const TRANSLATION_PREFIX_NOTIFICATIONS = 'notifications';

export const NOTIFICATION_EVENTS = {
  NOTICE_PUBLISHED: 'NOTICE_PUBLISHED',
  CONTENT_PROMOTED: 'CONTENT_PROMOTED',
  FORUM_POST_REPLY: 'FORUM_POST_REPLY'
};
