import React from 'react';
import MainApp from '@/modules/MainApp';
import {useUser} from '@dofleini/security';
import UnauthenticatedApp from '@/modules/UnauthenticatedApp';
import {authentication} from '@/settings/authentication';
import OnboardingModule from '@/modules/onboarding';
import {message} from 'antd';
import {useTranslation} from 'react-i18next';

function App() {
  const {user, hasPermission} = useUser();
  const {t} = useTranslation('common');
  
  if (user && authentication?.activeOnboarding && !user?.onboardingCompleted)
    return <OnboardingModule/>;
    
  if (user)
    if(hasPermission(['ADMIN', 'ADMIN:COMMUNITY']))
      return <MainApp/>;
    else{
      message.warn(t('onlyAdminAccess'));
    }
    
  return <UnauthenticatedApp/>;
}

export default App;
