import React, {memo, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {List, Avatar, Divider, Typography} from 'antd';
import renderTitleByType from './components/renderTitleByType';
import renderDescriptionByType from './components/renderDescriptionByType';
import style from './index.less';
import classNames from 'classnames';
import moment from 'moment';
import {useGetOneUser} from '@/modules/admin/hooks/useUser';
import {getFromSource} from '@/utils/getImages';
import {useHistory} from 'react-router-dom';
import {useGetOneCommunity} from '@/modules/community/hooks/useCommunity';
import {useTranslation} from 'react-i18next';
import {useUpdateNotification} from '@/hooks/useNotifications';

const {Text} = Typography;

const NotificationItem = ({item}) => {
  const {data: dataOwner, isLoading: isLoadingOwner} = useGetOneUser({id: item.owner});

  const {t} = useTranslation('notifications');

  const {push} = useHistory();

  const [update] = useUpdateNotification(item?._id);

  const communityId = useMemo(() => {
    if (item?.body)
      return item?.body?.communityId;
    return '';
  }, [item.body]);

  const {data: dataCommunity} = useGetOneCommunity({id: communityId});

  const whichCommunity = useMemo(() => {
    return (
      <Text level={3}>{dataCommunity&&dataCommunity?.data?.name}</Text>
    );
  }, [dataCommunity]);

  const onClickNotification = useCallback(() => {
    if (!item.viewed) {
      if (item.event === 'FORUM_POST_REPLY') {
        update({viewed: true, _id: item?._id})
          .then(() => {
            push(`/${dataCommunity?.data?.name}/${item?.body?.entity}/${item?.body?.topicId}/reply/${item?.body?._id}`);
            item.closeDialog();
          })
          .catch(err => console.log('error to update', err));
      } else {
        update({viewed: true, _id: item?._id})
          .then(() => {
            push(`/${dataCommunity?.data?.name}/${item?.body?.entity}/${item?.body?._id}`);
            item.closeDialog();
          })
          .catch(err => console.log('error to update', err));
      }
    } else {
      if (item.event === 'FORUM_POST_REPLY') {
        push(`/${dataCommunity?.data?.name}/${item?.body?.entity}/${item?.body?.topicId}/reply/${item?.body?._id}`);
        item.closeDialog();
      } else {
        push(`/${dataCommunity?.data?.name}/${item?.body?.entity}/${item?.body?._id}`);
        item.closeDialog();
      }

    }
  }, [dataCommunity, item, push, update]);

  return (<>
    <div
      id={`notification-item-${item._id}`}
      className={classNames('py-4 px-6 w-full', {'bg-lightPrimary': !item.read})}>
      <div className="opacity-75">
        {moment(item?.createdAt).fromNow()}
        {communityId && <span className="font-semibold ml-2">{t('inTheCommunity')} {whichCommunity}: </span>}
      </div>
      <List.Item className="px-0 m-0 py-2">
        <List.Item.Meta
          onClick={onClickNotification}
          id={`notification-item-meta-${item._id}`}
          className={classNames(style.notificationMeta, 'm-0 cursor-pointer')}
          avatar={<Avatar src={!isLoadingOwner ? getFromSource(dataOwner?.data?.avatar) : ''}/>}
          title={renderTitleByType(item, dataOwner?.data)}
          description={renderDescriptionByType(item)}
        />
      </List.Item>
    </div>
    <Divider className="w-full m-0"/>
  </>);
};

NotificationItem.propTypes = {
  item: PropTypes.object,
  style: PropTypes.any,
  isVisible: PropTypes.any,
  updateNotification: PropTypes.func,
};

export default memo(NotificationItem);