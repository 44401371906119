import PropTypes from 'prop-types';
import React, {createContext, memo, useCallback, useContext, useMemo, useReducer} from 'react';
import {useTranslation} from 'react-i18next';
import {cellRender} from '@/components/TableGrid/CellRender';
import {useResponsive} from '@/contexts/ResponsiveContext';


export const TABLE_SIZE = {
  big: 'big',
  middle: 'middle',
  small: 'small'
};

const TableContext = createContext();

const initialState = {hidden: {}, size: TABLE_SIZE.middle};


const updateSettingTable = (table, value = initialState) => {
  value.table = table;
  localStorage.setItem(table, JSON.stringify(value));
  return value;
};

const getInitial = (id) => {
  id = id || window?.location?.pathname;
  const table = `__table_${id}`;
  try {
    const settings = localStorage.getItem(table);
    if (settings) {
      let settingTable = JSON.parse(settings);
      if (settingTable.table === table) {
        settingTable = Object.assign({}, initialState, settingTable);
        return settingTable;
      }
    }
    return updateSettingTable(table);
  } catch (e) {
    return updateSettingTable();
  }
};

function reducer(state, action) {
  let finalState = state;
  switch (action.type) {
    case 'hide':
      state.hidden = {...state.hidden, [action.payload.column]: false};
      finalState = {...state};
      break;
    case 'show':
      state.hidden = {...state.hidden, [action.payload.column]: false};
      finalState = {...state};
      break;
    case 'toggle':
      state.hidden = {...state.hidden, [action.payload.column]: !state.hidden[action.payload.column]};
      finalState = {...state};
      break;
    case 'showAll':
      finalState = {...state, hidden: {}};
      break;
    case 'hideAll':
      finalState = {...state, hidden: action.payload};
      break;
    //size
    case 'changeSize':
      finalState = {...state, size: action.payload};
      break;
    default:
      throw new Error();
  }
  return updateSettingTable(finalState.table, finalState);
}

function TableContextProviderDef({id, children, columns, translation, useMapColumns}) {
  const [state, dispatch] = useReducer(reducer, getInitial(id));
  const {isMobile} = useResponsive();
  const {t} = useTranslation(translation || 'common');
  const hidden = state.hidden;

  const mappedColumns = useMapColumns(columns);

  const {visibleColumns, columnsRender} = useMemo(() => {
    const columnsRender = mappedColumns.map((({title, render, ...col}) => ({
      ...col,
      title: t(title),
      visible: !hidden[col.dataIndex],
      render: (text, record, index) => cellRender(text, record, index, col.type, render, col)
    })));
    const visibleColumns = isMobile ? columnsRender : columnsRender.filter(col => col.visible);
    return {
      columnsRender,
      visibleColumns
    };
  }, [mappedColumns, hidden, isMobile, t]);

  const handleDeletedCols = useCallback((dataIndex) => {
    dispatch({type: 'toggle', payload: {column: dataIndex}});
  }, []);

  const toggleAllHidden = useCallback((showAll) => {
    if (showAll)
      dispatch({type: 'showAll'});
    else {
      const all = {};
      mappedColumns.forEach(({dataIndex}) => all[dataIndex] = true);
      dispatch({type: 'hideAll', payload: all});
    }
  }, [mappedColumns]);

  const handleChange = useCallback(({key}) => {
    if (TABLE_SIZE[key])
      dispatch({type: 'changeSize', payload: key});
  }, []);

  return (
    <TableContext.Provider value={{
      columns: columnsRender,
      visibleColumns,
      // fixedColumns,
      handleDeletedCols,
      toggleAllHidden,
      // handleFixCol,
      size: state.size,
      handleChange
    }}>
      {children}
    </TableContext.Provider>
  );

}

function useTableGrid() {
  const context = useContext(TableContext);
  return context || {};
}

const TableContextProvider = memo(TableContextProviderDef);

export {TableContextProvider, useTableGrid};

TableContextProviderDef.propTypes = {
  children: PropTypes.any,
  columns: PropTypes.array,
  id: PropTypes.string,
  translation: PropTypes.string,
  useMapColumns: PropTypes.func
};

TableContextProviderDef.defaultProps = {
  useMapColumns: v => v
};
