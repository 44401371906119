import React, {createContext, useCallback, useContext, useState} from 'react';
// import PropTypes from 'prop-types';

const FilterContext = createContext();

function FilterProvider(props) {
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState('');
  const [isOpen, setOpen] = useState(true);

  const closeFilter = useCallback(() => {
    setOpen(false);
  }, []);
  const openFilter = useCallback(() => {
    setOpen(true);
  }, []);
  const toggleFilter = useCallback(() => {
    setOpen(prevState => !prevState);
  }, []);

  return (
    <FilterContext.Provider value={{
      isOpen,
      closeFilter,
      openFilter,
      toggleFilter,
      filter,
      setFilter,
      search,
      setSearch,
      sort,
      setSort
    }} {...props} />
  );
}

function useFilter() {
  const context = useContext(FilterContext);
  if (context === undefined) {
    return {};
  }
  const {
    isOpen,
    closeFilter,
    openFilter,
    toggleFilter,
    filter,
    setFilter,
    search,
    setSearch,
    sort,
    setSort
  } = context;

  return {
    isOpen,
    closeFilter,
    openFilter,
    toggleFilter,
    filter,
    setFilter,
    search,
    setSearch,
    sort,
    setSort
  };
}

FilterProvider.propTypes = {};


export {FilterProvider, useFilter};
