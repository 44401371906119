import React, {memo} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import {Tag} from 'antd';

const TagList = ({data, renderField, tag, conditionalColor}) => {
  if (!data) return '';
  return data.map((item, key) => {
    const text = renderField ? get(item, renderField) : item;
    const color = conditionalColor ? conditionalColor(item) : undefined;
    if (tag)
      return text ? (<Tag color={color} key={key}>
        {text}
      </Tag>) : '';
    return <div key={key}>{text}</div>;
  });

};

export default memo(TagList);

// TagList.defaultProps = {
//   data: []
// };
TagList.propTypes = {
  conditionalColor: PropTypes.func,
  data: PropTypes.array,
  renderField: PropTypes.string
};
