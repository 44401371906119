import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {usePresentation} from '../../hooks';
import useNavigation from '@/hooks/useNavigation';

const UserActionsMenu = ({config, mode, theme, collapsed, onClick, ...props}) => {
  const menu = useNavigation(config?.options);

  const ViewPresentation = usePresentation(config);

  return <ViewPresentation mode={mode} theme={theme} onClick={onClick} collapsed={collapsed} menu={menu} config={config} selectable={false} {...props}/>;
};

export default memo(UserActionsMenu);

UserActionsMenu.propTypes = {
  mode: PropTypes.string,
  theme: PropTypes.oneOf(['dark', 'light']),
  config: PropTypes.object,
  onClick: PropTypes.func,
  collapsed: PropTypes.any
};

UserActionsMenu.defaultProps = {
  mode: 'inline',
  theme: 'dark',
  config: {}
};