import {useMemo} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const DatePreview = ({value, showToday, format}) => {
  return useMemo(() => {
    if (value || showToday)
      return moment(value).format(format || 'LL').toString();
    return '-';
  }, [format, showToday, value]);
};

export default DatePreview;

DatePreview.propTypes = {
  value: PropTypes.string,
  showToday: PropTypes.bool,
  format: PropTypes.string
};
