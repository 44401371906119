import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {BellFilled} from '@ant-design/icons';
import style from '@/layout/TopLayout/index.less';
import classNames from 'classnames';
import NavigationMenu from '@/layout/Menu/NavigationMenu';
import Logo from '@/layout/TopLayout/Logo';

const ExpandedLayout = ({hint, extras, children}) => {

  return (<>
    <div className={style.topLayoutContainer}>
      <div className={style.contentContainer}>
        <div className={classNames(['flex', hint ? 'justify-between' : 'justify-end'])}>
          {hint}
        </div>
        <div className={classNames(['flex justify-between my-5'])}>
          <Logo/>
          <div className={'flex items-center'}>
            <div className={'mr-5'}>
              {extras}
            </div>
            <div className={'mr-5 text-xl'}>
              <BellFilled/>
            </div>
            {/*{userAvatar}*/}
          </div>
        </div>
      </div>

      <div className={style.menuContainer}>
        <NavigationMenu mode={'horizontal'} theme={'light'}/>
      </div>
    </div>
    <div className={style.contentContainer}>
      {children}
    </div>
  </>
  );

};

export default memo(ExpandedLayout);

ExpandedLayout.propTypes = {
  children: PropTypes.any,
  extras: PropTypes.any,
  hint: PropTypes.any
};
