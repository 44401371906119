import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';
import {Button, Dropdown, Menu, message} from 'antd';
import {ControlOutlined} from '@ant-design/icons';
import {CACHE_KEY_STATS_USER, USER_STATUS} from '@/modules/admin/contants/users';
import map from 'lodash/map';
import {useTranslation} from 'react-i18next';
import {queryCache, useMutation} from 'react-query';
import UserApiService from '@/modules/admin/services/UserApiService';
import UserStatus from '@/modules/admin/components/UserStatus';
import {useUserList} from '@/modules/admin/containers/UserEntityContainer';

const statusFilter = {
  DELETED: true,
  UNVERIFIED: true,
};

const UserStatusSelect = ({users, onChange}) => {
  const {t} = useTranslation('admin');
  const {activeKey} = useUserList();
  const [mutate, {isLoading}] = useMutation(UserApiService.updateStatus, {
    throwOnError: true,
    onSuccess: () => {
      queryCache.invalidateQueries(activeKey);
      queryCache.invalidateQueries(CACHE_KEY_STATS_USER);
      message.success(t('updateSuccess'));
    },
  });
  const hasUsers = users && Array.isArray(users);
  const handleChange = useCallback(async ({key}) => {
    if (hasUsers) {
      try {
        await mutate({users, status: key});
        onChange && onChange(key);
      } catch (e) {
        message.error('Error');
      }
    }
  }, [hasUsers, mutate, users, onChange]);
  
  const menu = (
    <Menu onClick={handleChange}>
      {
        map(USER_STATUS, (status) => !statusFilter[status] ? (
          <Menu.Item key={status}>
            <UserStatus status={status} full help/>
          </Menu.Item>
        ) : '')
      }
    </Menu>
  );
  
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button loading={isLoading} type="link" disabled={isLoading || !hasUsers}
        icon={<ControlOutlined/>}>{t('user.changeStatus')}</Button>
    </Dropdown>
  );
  
};

export default memo(UserStatusSelect);

UserStatusSelect.propTypes = {
  onChange: PropTypes.func,
  users: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};
