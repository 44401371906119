/* eslint-disable react-hooks/rules-of-hooks */
import PropTypes from 'prop-types';
import React, {memo, useCallback, useMemo} from 'react';
import {Button, Form, Popconfirm} from 'antd';
import {useTranslation} from 'react-i18next';
import FloatContainer, {TYPE_CONTAINER} from '@/components/FloatContainer';
import useCrud from '@/hooks/useCrud';
import {useHistory} from 'react-router-dom';
import {RouteLoader} from '@dofleini/security';
import FormSkeleton from '@/components/FormGenerator/FormSkeleton';
import {useFormTitle} from '@/hooks/useFormTitle';
import OpenDetail from '@/components/OpenDialog';
import {DeleteOutlined, FormOutlined} from '@ant-design/icons';
import {EntityDetailContent} from '@/components/EntityDetailContent';
import isEmpty from 'lodash/isEmpty';
import FormBuilder from 'antd-form-builder';
import useDeepCompareEffect from 'use-deep-compare-effect';
import {DetailsContextProvider, useDetailsContext} from '@/contexts/DetailsContext';

const headerActions = ({headerProps = {}, data, t, viewMode, onSubmit, toggleView, handleClose, onDelete, isLoading, form}) => {
  const {extras: ExtraOptions, canRemove, canEdit, extrasEdit: ExtraOptionsEdit, hideSaveEdit, hideCancelEdit} = headerProps;
  if (viewMode)
    return [
      ExtraOptions && <ExtraOptions
        key={'detail-extra-actions'} data={data}
        toggleView={toggleView}
        isLoading={isLoading}
        viewMode={viewMode}/>,
      canEdit && <Button key={'edit'}
        type={'primary'}
        icon={<FormOutlined/>}
        onClick={toggleView}
        disabled={isLoading}>{t('edit')}</Button>,
      canRemove && <Popconfirm
        key={'remove'}
        placement="bottomRight"
        title={t('deleteConfirmation')}
        onConfirm={onDelete}
        okText={t('delete')}
        cancelText={t('cancel')}
      >
        <Button icon={<DeleteOutlined/>} loading={isLoading}>{t('delete')}</Button>
      </Popconfirm>
    ];
  return (
    [
      ExtraOptionsEdit && <ExtraOptionsEdit
        data={data}
        onSubmit={onSubmit}
        toggleView={toggleView}
        isLoading={isLoading}
        viewMode={viewMode}
        onCancel={handleClose}
        form={form}/>,
        
      !hideSaveEdit && <Button
        key="submit"
        type="primary"
        onClick={onSubmit}
        loading={isLoading}
      >
        {t('save')}
      </Button>,
      !hideCancelEdit && <Button key="back" onClick={handleClose} disabled={isLoading}>
        {t('cancel')}
      </Button>,
    ]
  );
};

export const createEntityDetail = (
  {
    route, service, name, module, translation, translationPrefix, useFields, useGetOne, options = {
      create: true,
      edit: true,
      details: true
    },
    willForceUpdate,
    getOneKey = 'getOne',
    mapPayload = v => v,
    mapResponse = v => v,
    ...props
  }) => {
  // eslint-disable-next-line react/display-name
  const FormComponent = memo(({headerProps}) => {
    const {t} = useTranslation(translation);
    const {goBack} = useHistory();
    const {create, update, remove} = useCrud(service, module, getOneKey);
    const {isOpen, closeDialog, payload} = useDetailsContext(name);
    const {isLoading, data: res} = useGetOne(payload); //todo do something with isError, error,
    const [form] = Form.useForm();
    const {title, viewMode, toggleView, backToView} = useFormTitle();
    const fields = useFields(viewMode, form);
    const forceUpdate = FormBuilder.useForceUpdate();
    
    const data = useMemo(() => mapResponse(res?.data), [res]);
    
    const initValues = data || {};
    
    const id = initValues?._id;
    const onClose = useCallback(() => {
      if (backToView) {
        return toggleView();
      }
      closeDialog();
      setTimeout(() => {
        goBack();
        form.resetFields();
      }, 500);
    }, [backToView, closeDialog, form, goBack, toggleView]);
    
    
    const handleFinish = useCallback(async (values) => {
      const mappedValues = mapPayload(values, !!id);
      if (id) {
        const {data} = await update({_id: id, ...mappedValues});
        form.setFieldsValue(mapResponse(data));
      } else
        await create(mappedValues);
      onClose();
    }, [id, create, onClose, update, form]);
    
    const handleDelete = useCallback(async () => {
      if (initValues) {
        await remove(initValues?._id);
        onClose();
      }
    }, [onClose, initValues, remove]);
    
    const handleClose = useCallback(() => {
      form.setFieldsValue(initValues);
      onClose();
    }, [form, initValues, onClose]);
    
    useDeepCompareEffect(() => {
      if (!isEmpty(initValues)) {
        form.setFieldsValue(initValues);
      }
    }, [initValues]);
    
    return (
      <FloatContainer
        type={TYPE_CONTAINER.DRAWER}
        {...props}
        visible={isOpen}
        title={t(`${translationPrefix}.${title}`)}
        onClose={onClose}
        footer={headerActions({
          headerProps,
          isLoading,
          handleClose,
          data: initValues,
          onDelete: handleDelete,
          onSubmit: () => form.submit(),
          t,
          toggleView,
          viewMode,
          form
        })}>
        <Form
          form={form} onFinish={handleFinish} layout={!viewMode ? 'vertical' : 'horizontal'}
          onValuesChange={willForceUpdate ? forceUpdate : false}>
          {isLoading ?
            <FormSkeleton/> :
            <EntityDetailContent viewMode={viewMode} form={form} data={initValues} fields={fields}/>
          }
        </Form>
      </FloatContainer>
    );
  });
  
  const pathRoute = [];
  if (options?.create) {
    route && pathRoute.push(`${route}/create`);
  }
  if (options?.details) {
    route && pathRoute.push(`${route}/:id`);
  }
  if (options?.edit) {
    route && pathRoute.push(`${route}/:id/edit`);
  }
  
  const routes = {
    NestedView: {
      path: pathRoute,
      exact: true,
      data: {
        dialog: name
      },
      component: OpenDetail
    }
  };
  
  // eslint-disable-next-line react/display-name
  const Detail = memo((props) => {
    return <DetailsContextProvider>
      <FormComponent {...props}/>
      <RouteLoader routes={routes}/>
    </DetailsContextProvider>;
  });
  FormComponent.propTypes = {
    headerProps: PropTypes.object
  };
  FormComponent.defaultProps = {
    headerProps: {canRemove: true, canEdit: true}
  };
  return {FormComponent, Detail};
};


createEntityDetail.propTypes = {
  module: PropTypes.any,
  name: PropTypes.any,
  options: PropTypes.object,
  route: PropTypes.any,
  service: PropTypes.any,
  translation: PropTypes.any,
  translationPrefix: PropTypes.any,
  useFields: PropTypes.any,
  useGetOne: PropTypes.any,
  willForceUpdate: PropTypes.bool,
  mapPayload: PropTypes.func,
};
