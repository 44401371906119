import React from 'react';
import PropTypes from 'prop-types';
import {ReactQueryDevtools} from 'react-query-devtools';
import SecurityProvider from '@/contexts/SecurityProvider';
import {DialogsContextProvider} from '@/contexts/DialogsContext';
import {DetailsContextProvider} from '@/contexts/DetailsContext';

export const AppContextProvider = ({children}) => {

  return (
    <SecurityProvider>
      <DialogsContextProvider>
        <DetailsContextProvider>
          {children}
        </DetailsContextProvider>
      </DialogsContextProvider>
      <ReactQueryDevtools initialIsOpen position="bottom-right"/>
    </SecurityProvider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.object,
};
