import {useMemo} from 'react';
import {useQuery} from 'react-query';

export const createGetOneHook = (service, keyId, name, customMethod, mapPayload = v => v) => {
  return (params) => {
    const relatedKey = useMemo(() => params?.[keyId], [params]);

    const queryConfig = useMemo(() => {
      return {enabled: !!relatedKey};
    }, [relatedKey]);

    return useQuery([relatedKey, name], async () => {
      const serviceCall = customMethod || service.getOne;
      const {data} = await serviceCall(relatedKey);
      return {
        data: mapPayload(data)
      };
    }, queryConfig);
  };
};
