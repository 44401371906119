import PropTypes from 'prop-types';
import React, {memo, useCallback} from 'react';
import {Button, Popconfirm} from 'antd';
import {useTranslation} from 'react-i18next';
import useCrud from '@/hooks/useCrud';
import UserApiService from '@/modules/admin/services/UserApiService';
import {useHistory, useLocation} from 'react-router-dom';
import {queryCache} from 'react-query';

const UserDelete = ({user}) => {
  const {t} = useTranslation('admin');
  const {push} = useHistory();
  const {pathname} = useLocation();

  const {remove} = useCrud(UserApiService, 'user');

  const handleDeleteUser = useCallback(() => {
    remove(user._id).then(() => {
      queryCache.invalidateQueries('users');
      push(pathname.split(user._id)[0]);
    });
  }, [pathname, push, remove, user._id]);

  return (
    <Popconfirm
      key={'remove'}
      placement="bottomRight"
      title={t('deleteConfirmation')}
      onConfirm={handleDeleteUser}
      okText={t('delete')}
      cancelText={t('cancel')}
    >
      <Button
        className={'px-0'}
        danger
        type={'text'}>{t('user.form.userDelete')}</Button>

    </Popconfirm>
  );
};

export default memo(UserDelete);


export const renderUserDelete = (value, form, user) => <UserDelete user={user}/>;

UserDelete.propTypes = {
  user: PropTypes.object
};
