import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useLocation} from 'react-router-dom';
import {Input} from 'antd';
import {useExistCommunity} from '@/modules/community/hooks/useCommunity';

const CommunityName = ({value, onChange, setCommunityName}) => {
  const {pathname} = useLocation();

  const [search, setSearch] = useState(null);

  const {data} = useExistCommunity(search);

  useEffect(() => {
    if (data?.length) {
      setCommunityName(data[0].name);
    }
  }, [data, setCommunityName]);

  const isSettingsPage = useMemo(() => {
    let path = pathname.split('/');

    return path[2] === 'settings';
  }, [pathname]);

  const onSearch = useCallback((e) => {
    setSearch(e.target.value);
  }, []);
    
  return (
    <div style={{maxWidth: isSettingsPage ? '25%' : '100%'}}>
      <div className={'flex justify-between items-center'}>
        <Input value={value} onChange={ e => {
          onChange(e);
        }}
        onBlur={onSearch}
        />
      </div>
    </div>
  );
};

CommunityName.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  setCommunityName: PropTypes.func
};

CommunityName.defaultValue = {
  setSearch: () => {}
};

export default memo(CommunityName);
