import React, {memo, useMemo} from 'react';
import {Badge} from 'antd';
import {BellOutlined} from '@ant-design/icons';
import styles from './index.less';
import {useCommunity} from '@/modules/community/contexts/CommunityContext';
import {useNotifications} from '@/hooks/useNotifications';
import {CACHE_KEY_LIST_NOTIFICATIONS} from '@/constants/notifications';

const IconNotifications = () => {

  const {currentId} = useCommunity();

  const {data} = useNotifications(CACHE_KEY_LIST_NOTIFICATIONS, currentId);

  const haveNotRead = useMemo(() => {
    return !!data?.some(v => !v.viewed);
  }, [data]);

  return (
    <span className="anticon ant-menu-item-icon mr-0 custom-icon-notification">
      <BellOutlined />
      {haveNotRead && <Badge dot status="processing" className={styles.badgeNotify}/>}
    </span>
  );
};

export default memo(IconNotifications);
