import React, {memo, useCallback, useState} from 'react';
// import PropTypes from 'prop-types';
import {Button, Form, message, Modal, Switch} from 'antd';
import Password from '@/modules/authentication/components/Password';
import {PasswordRuler} from '@dofleini/validator';
import {useTranslation} from 'react-i18next';
import style from './index.less';
import {generatePassword} from '@/utils/password';
import {useMutation} from 'react-query';
import UserApiService from '@/modules/admin/services/UserApiService';
import {useDialogsContext} from '@/contexts/DialogsContext';

export const RESET_PASSWORD_DIALOG = 'reset-password';

const passwordField = (t, getFieldValue, autoPass, isLoading) => {
  const auto = getFieldValue('autoGeneratePassword');

  if (auto || auto === undefined)
    return (
      <Form.Item label={t('password')}>
        <Password
          disabled={isLoading}
          readOnly
          className={style.readOnly}
          value={autoPass}
          lateral={false}
          size={'large'}
          placeholder={t('password')}
        />
      </Form.Item>
    );
  return (
    <Form.Item
      name="password"
      label={t('password')}
      rules={[
        {required: true},
        PasswordRuler
      ]}
    >
      <Password
        disabled={isLoading}
        lateral={false}
        size={'large'}
        placeholder={t('password')}
      />
    </Form.Item>
  );
};

const ResetPasswordModal = () => {
  const {isOpen, closeDialog, payload} = useDialogsContext(RESET_PASSWORD_DIALOG);
  const {t} = useTranslation('admin');
  let [autoPass, setAutoPass] = useState(generatePassword());
  const [mutate, {isLoading}] = useMutation(UserApiService.passwordReset, {throwOnError: true});
  const hasUser = payload;
  const user = payload?._id;
  const [form] = Form.useForm();

  const onCancel = useCallback(() => {
    closeDialog();
    form.resetFields();
  }, [closeDialog, form]);

  const handleSubmit = useCallback(async () => {
    const values = await form.validateFields();
    values.password = values.autoGeneratePassword ? autoPass : values.password;
    values._id = user;
    if (hasUser) {
      try {
        await mutate(values);
        form.resetFields();
        const pass = generatePassword();
        setAutoPass(pass);
        form.setFieldsValue({password: pass});
        onCancel();
        message.success(t('message.passwordChanged'));
      } catch (e) {
        message.error('Error');
      }
    }
  }, [autoPass, form, hasUser, mutate, onCancel, t, user]);

  return (
    <Modal
      visible={isOpen}
      title={t('resetPasswordTitle', {for: payload?.fullName || 'user'})}
      onOk={handleSubmit}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel} disabled={isLoading}>
          {t('cancel')}
        </Button>,
        <Button key="submit" type="primary"
          onClick={handleSubmit}
          disabled={isLoading}
          loading={isLoading}>
          {t('ok')}
        </Button>,
      ]}
    >
      <Form form={form}
        onFinish={handleSubmit}
        initialValues={{autoGeneratePassword: true, changePasswordRequire: true, password: autoPass}}
        layout="vertical">
        <div className={style.lateralRightSwitch}>
          <Form.Item
            name="autoGeneratePassword"
            label={t('user.autoGeneratePassword')}
            valuePropName="checked"
          >
            <Switch disabled={isLoading}/>
          </Form.Item>
        </div>
        <Form.Item
          shouldUpdate={(prevValues, currentValues) => prevValues.autoGeneratePassword !== currentValues.autoGeneratePassword}
          noStyle>
          {
            ({getFieldValue}) => passwordField(t, getFieldValue, autoPass, isLoading)
          }
        </Form.Item>
        <div className={style.lateralRightSwitch}>
          <Form.Item
            name="changePasswordRequire"
            valuePropName="checked"
            label={t('user.askForChangePassword')}
          >
            <Switch disabled={isLoading}/>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );

};
// <label className={'ml-4'}>{t('user.askForChangePassword')}</label>

export default memo(ResetPasswordModal);

