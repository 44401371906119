import {ApiClientService, EntityApiService} from '@dofleini/security';

class CommunityApiService extends EntityApiService {

    search = (params, config) => {
      return ApiClientService.post(this.getPath('/all/search', params), params, config);
    }

    getOne = (id, config) => {
      return ApiClientService.get(this.getPath() + '/' + id, config);
    }

    getOneByName = (name, config) => {
      return ApiClientService.get(this.getPath() + '/name/' + name, config);
    }

    setNameSpace = (spaceName) => {
      localStorage.setItem('name-workspace', spaceName);
    }

    getNameSpace = () => {
      return localStorage.getItem('name-workspace');
    }

    setCurrentId = (currentId) => {
      localStorage.setItem('community-current-id', currentId);
    }

    getCurrentId = () => {
      return localStorage.getItem('community-current-id');
    }

    promoted = ({_id, body}) => {
      return ApiClientService.patch(`/ms-core/api/communities/${_id}/promote`, body);
    };

    getOnCover = () => {
      return ApiClientService.post(`/ms-core/api/communities/${this.getCurrentId()}/on-cover`);
    };

    getQR = (communityId) => {
      return ApiClientService.get(this.getPath(`/${communityId}/qr`));
    }
}

export default new CommunityApiService('/ms-core/api/community');
