import {queryCache, useMutation, useQuery} from 'react-query';
import NotificationsApiService from '@/services/NotificationsApiService';
import {EmptyFilter, FilterFactory} from '@dofleini/query-builder';
import {CACHE_KEY_LIST_NOTIFICATIONS} from '@/constants/notifications';

export const useNotifications = (key, communityId) => {
  let filters = new EmptyFilter();

  let keys = [key];
  
  if(communityId) {
    filters = FilterFactory.add(filters, {
      field: 'body.communityId',
      value: communityId,
    });
    keys.push(communityId);
  }

  const {isLoading, isError, error, data} = useQuery(keys, async () => {
    const {data} = await NotificationsApiService.getNotifications({filters: filters.toQuery()}, {headers: {}});
    return (data);
  }, {
    enabled: !!communityId
  });

  return {isLoading, isError, error, data};
};

export const useUpdateNotification = (notifyId) => useMutation(NotificationsApiService.update, {
  onSuccess: () => {
    queryCache.invalidateQueries(CACHE_KEY_LIST_NOTIFICATIONS);
    queryCache.invalidateQueries(notifyId);
  }
});