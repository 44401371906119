import React, {createContext, useContext, useCallback, useEffect, useState} from 'react';
import {ApiClientService, useUser} from '@dofleini/security';
import {authentication} from '@/settings';
import moment from 'moment';

const SessionControlContext = createContext();

const REFRESH_TOKEN_KEY = '__refresh_token__';
const SPACE_KEY = '__space__';
const EXPIRE_DATE_KEY = '__expire_date__';

export const setAuthData = (data = {}) => {
  const {accessToken, refreshToken, space} = data;
  window.localStorage.setItem(ApiClientService.ACCESS_TOKKEN_KEY, accessToken);
  window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  window.localStorage.setItem(SPACE_KEY, space);
  window.localStorage.setItem(EXPIRE_DATE_KEY, new Date().toISOString());
};

const getExpirationTime = () => window.localStorage.getItem(EXPIRE_DATE_KEY);

export const handleUserResponse = ({data}) => {
  setAuthData(data);
  return data;
};

export const refreshToken = () => {
  const token = window.localStorage.getItem(ApiClientService.ACCESS_TOKKEN_KEY);
  const refresh = window.localStorage.getItem(REFRESH_TOKEN_KEY);
  const space = window.localStorage.getItem(SPACE_KEY);
  return ApiClientService.post(`${authentication.path}/api/refresh-token`, {
    token,
    refresh,
    space
  }).then(handleUserResponse);
};

const SessionControlProvider = (props) => {
  const [expirationTime, setExpirationTime] = useState(getExpirationTime());
  const {user} = useUser();

  const refresh = useCallback(() => {
    !!user && refreshToken().then(({data}) => {
      setAuthData(data);
      setExpirationTime(getExpirationTime());
    });
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }

    const predictiveDate = moment(expirationTime).add(authentication.tokenExpirationTime, 'milliseconds');

    const difference = predictiveDate - moment();

    if (difference < 60000) {
      refreshToken();
      return;
    }

    const timer = setTimeout(() => {
      refreshToken();
    }, difference - 60000);

    return () => {
      clearTimeout(timer);
    };
  }, [expirationTime, user]);

  return (
    <SessionControlContext.Provider value={{refresh}} {...props}/>
  );
};

const useSessionControl = () => {
  const context = useContext(SessionControlContext);
  if (context === undefined) {
    throw new Error('useSessionControl must be used within a SessionControlProvider');
  }
  const {refresh} = context;
  return {refresh};
};

SessionControlProvider.propTypes = {};


export {SessionControlProvider, useSessionControl};
