import {LAYOUT} from '@dofleini/auth-layout';

export const authentication = {
  image: null,
  background: '#DDE4EC',
  formBackground: '#ffffff',
  layout: LAYOUT.CENTER_BOX,
  path: '/ms-auth',
  showInviteLInk: true,
  showUserName: false,
  showAddress: false,
  //in milliseconds
  tokenExpirationTime: 2 * 60 * 60 * 1000,
  activeOnboarding: false
};
