const source = process.env.REACT_APP_FILE_STORAGE;
const prefixSource = process.env.REACT_APP_PREFIX_FILE_STORAGE || '';

const sourcePath = prefixSource.startsWith('/') ? `${source}${prefixSource}`: `${source}/${prefixSource}`;

export const getFromSource = (image, defaultValue = null) => {
  if (typeof image !== 'string') return defaultValue;
  if (!image && !defaultValue) return '';
  if (!image && defaultValue) return defaultValue;
  const isSocialOrCDNAvatar =
        (image && image.startsWith('http')) || (image && image.startsWith('https'));
  const isBase64 = image && image.startsWith('data:image/png;base64');
  return isSocialOrCDNAvatar || isBase64 ? image : `${sourcePath}/${image}`;
};
