import {PhoneNumberRuler, PasswordRuler} from '@dofleini/validator';
import {renderAvatarSection} from '@/modules/admin/components/UserDetailContent/AvatarSection';
import renderAvatarViewWidget from '@/components/UserAvatar/renderAvatarViewWidget';
import UserRoles from '@/modules/admin/components/UserRoles';
import {createSearchFieldRemote} from '@/utils/createSearchFieldRemote';
import {useSearchRol} from '@/modules/admin/hooks/useSearchRol';
import createClearRenderView from '@/utils/createClearRenderView';
import {renderUserDelete} from '@/modules/admin/components/UserDelete';
import {renderFieldPassword} from '@/modules/admin/components/FieldPassword';

export const baseFields = [
  {
    key: 'avatar',
    colSpan: 2,
    onlyView: true,
    renderView: renderAvatarViewWidget
  },
  {
    key: 'avatar',
    colSpan: 2,
    // eslint-disable-next-line react/display-name
    render: renderAvatarSection,
    onlyEdit: true,
    label: 'user.form.avatar.placeholder'
  },
  {
    key: 'fullName',
    colSpan: 2,
    label: 'user.form.fullName.placeholder',
    onlyView: true,
  },
  {
    key: 'firstName',
    colSpan: 1,
    label: 'user.form.name.placeholder',
    onlyEdit: true,
    rules: [{required: true}],
  },
  {
    key: 'lastName',
    colSpan: 1,
    label: 'user.form.lastName.placeholder',
    onlyEdit: true
    
  },
  {
    key: 'phone',
    colSpan: 2,
    renderView: createClearRenderView(),
    label: 'user.form.phone.placeholder',
    rules: [PhoneNumberRuler]
  },
  {
    key: 'email',
    colSpan: 2,
    label: 'user.form.email.placeholder',
    renderView: createClearRenderView(),
    rules: [{required: true}, {type: 'email'}],
  },
];

const fields = [
  ...baseFields,
  {
    key: 'roles',
    colSpan: 2,
    label: 'user.form.roles',
    widget: createSearchFieldRemote({
      usePagination: useSearchRol,
      mode: 'multiple',
      fullEntity: true
    }),
    viewWidget: UserRoles
  },
  {
    key: 'createdAt',
    label: 'registerAt',
    type: 'date',
    onlyView: true
  },
  {
    key: 'lastAccessAt',
    label: 'lastAccessAt',
    type: 'date',
    onlyView: true
  },
  {
    key: 'password',
    label: 'setNewPassword',
    colSpan: 2,
    onlyEdit: true,
    widget: renderFieldPassword,
    rules: [PasswordRuler]
  },
  {
    key: 'userDelete',
    onlyView: true,
    renderView: renderUserDelete
  }
];

export {fields};
