import React, {memo, useCallback, useEffect, useMemo, useState} from 'react';
import {Grid, Layout} from 'antd';
import PropTypes from 'prop-types';
import {MenuFoldOutlined} from '@ant-design/icons';
import style from './index.less';
import {layout, LAYOUT_TYPE} from '@/settings';
import ResponsiveHeader from '@/layout/Horizontal/ResponsiveHeader';
import classNames from 'classnames';
import ResponsiveSider from '@/layout/Horizontal/ResponsiveSider';
import NavigationMenu from '@/layout/Menu/NavigationMenu';
import {Scrollbars} from 'react-custom-scrollbars';
import UserOptions from '@/components/UserOptions';
import styled from 'styled-components';
import Logo from '@/components/Logo';
import {useTranslation} from 'react-i18next';
import pick from 'lodash/pick';
import {useLocation} from 'react-router-dom';
import {useResponsive} from '@/contexts/ResponsiveContext';
import NotificationDrawer from '@/components/NotificationDrawer';

const {useBreakpoint} = Grid;

const SiderBar = styled(Layout.Sider)`
    & > :first-child {
        display: flex;
        ${() => layout.type === LAYOUT_TYPE.HORIZONTAL && 'flex-direction: column;'}
    }
`;

const firstRenderCollapsed = () => {
  return document?.body?.clientWidth < 992;
};

const HorizontalLayout = ({children, ...props}) => {

  const {lg} = useBreakpoint();
  const {t} = useTranslation('navigation');
  const [collapsed, setCollapsed] = useState(firstRenderCollapsed());
  const collapsedWidth = !lg ? 0 : layout.options.collapsedWidth;
  const toggleCollapsed = useCallback(  () => setCollapsed(prevState => !prevState), []);
  
  const siderBarOptions = useMemo(() => pick(layout.options, ['width', 'zeroWidthTriggerStyle', 'theme']), []);

  const {isMobile} = useResponsive();
  const location = useLocation();

  useEffect(() => {
    if (isMobile) {
      setCollapsed(true);
    }
  }, [isMobile, location]);

  const trigger = <div
    className={classNames('sidebar-trigger', {[`position-${layout.options.triggerPosition}`]: !collapsed})}>
    <MenuFoldOutlined
      className={classNames('transition duration-200 ease-in-out trigger-item-1',
        {'transform rotate-180': collapsed})}/>
    {
      layout.options.useTriggerText &&
      <span
        className={classNames('transition duration-200 ease-in-out m-2 trigger-item-2', {'hidden': collapsed})}>
        {t('sidebarTrigger')}
      </span>
    }
  </div>;

  return (
    <>
      {!lg && <ResponsiveHeader className={'z-10'} toggleSider={toggleCollapsed}/>}
      <div
        className={classNames(style.overlayer, {'hidden': collapsed}, 'lg:hidden')}
        onClick={toggleCollapsed}
      />
      <ResponsiveSider  {...layout.options} collapsed={collapsed} className={'hidden lg:block'}/>
      <SiderBar
        id="side-bar-nav"
        collapsible
        className={classNames(style.sider, 'fixed h-screen left-0 top-0 z-40')}
        collapsed={collapsed}
        onCollapse={setCollapsed}
        collapsedWidth={collapsedWidth}
        trigger={trigger}
        {...siderBarOptions}
      >
        <Scrollbars
          id="scroll-bars"
          // This will activate auto hide
          autoHide
          // Hide delay in ms
          autoHideTimeout={1000}
          // Duration for hide animation in ms.
          autoHideDuration={200}
          height={'100%'}
        >
          <div className={classNames('flex items-center', {'justify-center':collapsed})}>
            <Logo mini={collapsed}/>
          </div>
          <NavigationMenu {...layout.options} {...props}/>
        </Scrollbars>
        <UserOptions collapsed={collapsed} {...layout.options} {...props}/>
      </SiderBar>
      {
        children && <Layout className="relative">
          <div className={classNames(style.headerSpace, 'lg:hidden')}/>
          <NotificationDrawer/>
          {children}
        </Layout>
      }
    </>
  );
};

export default memo(HorizontalLayout);

HorizontalLayout.propTypes = {
  children: PropTypes.any
};
