import DatePreview from '@/components/DatePreview';

const mapFields = (t, fields) => fields.map(
  (field) => {
    const map = {
      ...field,
      label: field.label && t(field.label),
      placeholder: field.placeholder && t(field.placeholder),
      colSpan: field.colSpan || 2,
    };
    if (map.type === 'date') {
      map.viewWidget = DatePreview;
    }
    return map;
  }
);

export default (t, fields) => {
  if (fields)
    return mapFields(t, fields);
  return fields => mapFields(t, fields);
};