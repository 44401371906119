import React, {memo} from 'react';
import {application} from '@/settings';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {useCommunity} from '@/modules/community/contexts/CommunityContext';

const getComponent = (Src) => {
  if (typeof Src === 'string') {
    return <img className="my-4 cursor-pointer max-h-10" src={Src} alt={application.name}/>;
  }
  return <Src className="my-4 cursor-pointer max-h-10"/>;
};

const Logo = ({mini}) => {
  const {insideCommunity} = useCommunity();
  
  return <Link to="/" disabled={insideCommunity}>
    {mini ? getComponent(application.logo.srcMin) : getComponent(application.logo.src)}
  </Link>;
};

Logo.propTypes = {
  mini: PropTypes.any
};

export default memo(Logo);
