import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, Upload } from 'antd';
import { UploadOutlined, UserOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import useUploadImage from '@/components/UploadImage/useUploadImage';
import { useTranslation } from 'react-i18next';
import style from './index.less';
import classNames from 'classnames';
import {getFromSource} from '@/utils/getImages';

const UploadImage = ({ onChange, value, disabled, btnProps, btnText, className, CustomDefaultIcon }) => {
  const { t } = useTranslation('common');
  const { props, handleChange, imageUrl, loading } = useUploadImage(value, onChange);
  return (
    <ImgCrop rotate>
      <Upload
        disabled={disabled}
        name="avatar"
        listType="picture-card"
        className={classNames(style.avatarUploader, className)}
        showUploadList={false}
        {...props}
        onChange={handleChange}>
        <Avatar
          src={getFromSource(imageUrl)}
          icon={
            CustomDefaultIcon ?
              CustomDefaultIcon :
              <UserOutlined/>}
          size={90}/>
        <Button
          type="default"
          className={style.buttonUploader}
          loading={loading}
          icon={<UploadOutlined/>}
          {...btnProps}
        >
          {loading ? t('loading') : t(btnText)}
        </Button>
      </Upload>
    </ImgCrop>
  );
};

export default memo(UploadImage);

UploadImage.defaultProps = {
  value: '',
  btnProps: {},
  btnText: 'explore',
};

UploadImage.propTypes = {
  button: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  btnProps: PropTypes.object,
  btnText: PropTypes.string,
  CustomDefaultIcon: PropTypes.any
};

