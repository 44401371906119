import PropTypes from 'prop-types';
import React, {memo, useCallback, useState} from 'react';
import RolSelector from '@/modules/admin/components/RolSelector';
import Text from 'antd/es/typography/Text';
import {queryCache, useMutation} from 'react-query';
import UserApiService from '@/modules/admin/services/UserApiService';

const RenderRolActions = ({roles, user}) => {

  const [edidting, setEditing] = useState(false);
  const [update] = useMutation(UserApiService.updateUser, {throwOnError: true});

  const handleRolChange = useCallback((values) => {
    user.roles = values;
    update(user).then(() => {
      setEditing(false);
      queryCache.invalidateQueries('users');
    });
  }, [update, user]);

  return (
    edidting ?
      <div style={{maxWidth: 250, width: '100%'}}>
        <RolSelector onChange={handleRolChange} value={roles}/>
      </div>
      :
      <Text className={'lowerCase'} style={{color: '#1890FF'}}
        onClick={() => setEditing(true)}>{roles.map(rol => rol?.role?.name).join(', ')}</Text>
  );
};

export default memo(RenderRolActions);


/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Rol component in a table cell
 * @param {String} user this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {String} roles this is the value of the dataIndex of the table (we will ignored in this case)
 * */
export const renderUserRole = (roles, user) => {
  return (
    <RenderRolActions user={user} roles={roles}/>
  );
};


RenderRolActions.propTypes = {
  roles: PropTypes.array,
  user: PropTypes.object
};
