import {ReactComponent as LogoMin}  from '@/assets/images/logo/logo-mini.svg';
import {ReactComponent as Logo}  from '@/assets/images/logo/logo-normal.svg';

export const application = {
  name: 'Communa Admin',
  logo: {
    src: Logo,
    srcMin: LogoMin
  }
};
