import React, {memo, useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Dropdown, Tag} from 'antd';
import {CloseCircleTwoTone} from '@ant-design/icons';
import style from './index.less';
import classNames from 'classnames';
import {
  checkIfDisableFilter,
  createRenderByType,
  createRenderValueByType
} from '@/components/TableFilters/components/Types';
import {useTableFilters} from '@/components/TableFilters/contexts/TableFiltersContext';

const FilterItem = ({filter, t}) => {
  const {onRemoveActiveFilter, onChangeFilter} = useTableFilters();
  
  const [visible, setVisible] = useState(false);
  const [currentValue, setCurrentValue] = useState(false);
  const onClose = useCallback(() => onRemoveActiveFilter(filter), [filter, onRemoveActiveFilter]);
  
  const applyFilter = useCallback((value) => {
    onChangeFilter({value, name: filter.name});
    setCurrentValue(value);
    setVisible(false);
  }, [filter.name, onChangeFilter]);
  
  const renderComponent = useMemo(() => filter?.render || createRenderByType(filter?.type), [filter.render, filter.type]);
  const renderValue = useMemo(() => filter?.renderValue || createRenderValueByType(filter?.type), [filter.renderValue, filter.type]);
  
  const content = useMemo(() => renderComponent({filter, t, applyFilter}), [applyFilter, filter, renderComponent, t]);
  
  const eventContent = useMemo(() => (
    <Tag
      id={`tag-filter-${filter.name}`}
      className={classNames(style.tag, 'cursor-pointer flex items-center mx-1 w-fit my-1')}
      closable color="processing"
      onClose={onClose}
      closeIcon={<div className="text-xl leading-none ml-1"><CloseCircleTwoTone twoToneColor="#D9D9D9"/></div>}>
      <div className="flex flex-col md:flex-row md:items-center">
        <span className="pr-1 font-semibold text-black">
          {t(filter.name)}:
        </span>
        <span>{renderValue({filterValue: currentValue, t, filter, applyFilter})}</span>
      </div>
    </Tag>
  ), [applyFilter, currentValue, filter, onClose, renderValue, t]);
  
  return (
    <Dropdown
      disabled={filter?.disabled || checkIfDisableFilter(filter?.type)}
      overlay={(
        <div className="min-w-48 bg-white rounded  border-solid border border-border-color">{content}</div>
      )}
      onVisibleChange={setVisible}
      visible={visible}
      placement="bottomLeft"
      trigger={['click']}>
      {eventContent}
    </Dropdown>
  );
};

FilterItem.propTypes = {
  filter: PropTypes.object,
  t: PropTypes.func,
  onChangeFilter: PropTypes.func,
};

export default memo(FilterItem);
