import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {FlagTwoTone, FileTextTwoTone} from '@ant-design/icons';
import {useUser} from '@dofleini/security';
import {useTranslation} from 'react-i18next';
import {getUserFullName} from '@/utils/userFullName';
import {NOTIFICATION_EVENTS} from '@/constants/notifications';

export const SimpleContent = ({item, dataOwner}) => {
  const {t} = useTranslation('notifications');
  const {user} = useUser();

  const userName = useMemo(() => {
    return dataOwner?._id === user._id ? t('me') : getUserFullName(item?.owner);
  }, [dataOwner, item.owner, t, user._id]);

  const titleKey = useMemo(() => {
    return dataOwner?._id === user._id ? 'titleMe' : 'title';
  }, [dataOwner, user._id]);

  return (
    <span className="text-base">
      {`${userName}`}
      <span className="px-1 font-normal opacity-75">
        {t(`notificationType.${item?.type}.${titleKey}`)}
      </span>
    </span>
  );
};

SimpleContent.propTypes = {
  item: PropTypes.object,
  dataOwner: PropTypes.object
};

export const DescriptionSimpleContent = ({item}) => {

  const switchIcon = useCallback(type => {
    if (type) {
      switch(type) {
        case NOTIFICATION_EVENTS.CONTENT_PROMOTED:
          return <FlagTwoTone className="mr-2"/>;
        case NOTIFICATION_EVENTS.NOTICE_PUBLISHED:
          return <FileTextTwoTone className="mr-2"/>;
        default:
          return null;
      }
    }
  }, []);

  return (
    <>
      {switchIcon(item?.event)}
      <span className="font-normal text-sm font-semibold italic text-black">
        {item?.payload?.body?.title}
      </span>
    </>
  );
};

DescriptionSimpleContent.propTypes = {
  item: PropTypes.object
};