import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Tag} from 'antd';
import {useTranslation} from 'react-i18next';
import compose from 'lodash/fp/compose';
import mapFP from 'lodash/fp/map';
import uniqByFP from 'lodash/fp/uniqBy';
import compact from 'lodash/compact';

const roleRender = (role, t) => {
  const roleName = role?.name;
  const title = roleName === 'USER' || roleName === 'SUPER_ADMIN' ? t(`rol.${roleName}`) : roleName;
  let color;
  switch (roleName) {
    case 'USER':
      color = 'blue';
      break;
    case 'SUPER_ADMIN':
      color = 'green';
      break;
    default:
      color = '';
      break;
  }
  return (
    <Tag key={role?._id} color={color}>
      <span className={'flex-grow'}>{title}</span>
    </Tag>
  );
};

const UserRoles = ({value}) => {
  const {t} = useTranslation('admin');

  const rolesView = useMemo(() => compose(
    compact,
    mapFP((item) => roleRender(item, t)),
    uniqByFP('_id'),
  )(value), [value, t]);

  return (
    <div className={'flex flex-row items-center'}>
      {rolesView}
    </div>
  );
};

UserRoles.propTypes = {
  value: PropTypes.array
};

UserRoles.defaultProps = {
  value: []
};

export default memo(UserRoles);

export const renderUserRoles = (roles) => <UserRoles value={roles}/>;
