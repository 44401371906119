import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Layout} from 'antd';
import AppLayout from '@/layout/AppLayout';
import {ProfileDialog} from '@/modules/admin/containers/Profile';

const {Content} = Layout;

const MainLayout = ({children}) => {
  const headerProps = {canEdit: true, canRemove: false}; // Details Profile

  return (
    <Layout style={{minHeight: '100vh'}}>
      <AppLayout>
        <Content>
          {children}
          <ProfileDialog headerProps={headerProps}/>
        </Content>
      </AppLayout>
    </Layout>
  );
};

export default memo(MainLayout);

MainLayout.propTypes = {
  children: PropTypes.any
};
