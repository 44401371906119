import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {LeftOutlined} from '@ant-design/icons';
import {Button, Space} from 'antd';

const HeaderDrawerView = ({onClose, title, extras}) => {

  return <div className={'flex justify-between'}>
    <Space>
      <Button className={'-ml-2'} type="text" icon={<LeftOutlined/>} onClick={onClose}/>
      <h3 className="m-0">{title}</h3>
    </Space>
    <Space>
      {extras}
    </Space>
  </div>;
};


HeaderDrawerView.defaultProps = {
  onClose: () => null,
};

export default memo(HeaderDrawerView);

HeaderDrawerView.propTypes = {
  extras: PropTypes.array,
  onClose: PropTypes.func,
  title: PropTypes.string
};
