import {queryCache, useMutation} from 'react-query';
import {message, Modal} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import React from 'react';
import {useTranslation} from 'react-i18next';

export function showConfirm(t, description, onOk) {
  Modal.confirm({
    title: t('deleteConfirmation'),
    icon: <ExclamationCircleOutlined/>,
    content: description,
    onOk
  });
}

const useCrud = (service, keyList, keyOne) => {
  const {t} = useTranslation('common');
  const [create, {isLoading: isCreating}] = useMutation(service.save,
    {
      throwOnError: true,
      onSuccess: () => {
        if (Array.isArray(keyList)) {
          keyList.forEach(key => queryCache.invalidateQueries(key));
        } else
          queryCache.invalidateQueries(keyList);
        message.success(t('createSuccess'));
      },
      onError: ({response}) => {
        message.error(response?.data?.message || t('error'));
      }
    });
    
  const [update, {isLoading: isUpdating}] = useMutation(service.update,
    {
      throwOnError: true,
      onSuccess: ({data}) => {
        if (Array.isArray(keyList)) {
          keyList.forEach(key => queryCache.invalidateQueries(key));
        } else
          queryCache.invalidateQueries(keyList);
        message.success(t('updateSuccess'));
        queryCache.invalidateQueries(data && data._id ? data._id : keyOne);
      },
      onError: ({response}) => {
        message.error(response?.data?.message || t('error'));
      }
    });
    
  const [remove, {isLoading: isRemoving}] = useMutation(service.delete,
    {
      throwOnError: true,
      onSuccess: () => {
        message.success(t('deleteSuccess'));
        if (Array.isArray(keyList)) {
          keyList.forEach(key => queryCache.invalidateQueries(key));
        } else
          queryCache.invalidateQueries(keyList);
      },
      onError: ({response}) => {
        message.error(response?.data?.message || t('error'));
      }
    }
  );
  
  return {
    create,
    isCreating,
    update,
    isUpdating,
    remove,
    isRemoving,
    isLoading: isCreating || isUpdating || isRemoving
  };
};

export default useCrud;
