import React from 'react';
import {SimpleContent} from '@/components/NotificationDrawer/components/NotificationItem/components/SimpleContent';
import {NOTIFICATION_EVENTS} from '@/constants/notifications';
import {ForumContent} from '@/components/NotificationDrawer/components/NotificationItem/components/ForumContent';

// eslint-disable-next-line react/display-name
export default (notification, dataOwner) => {
  switch (notification?.type) {
    case NOTIFICATION_EVENTS.CONTENT_PROMOTED:
      return <SimpleContent item={notification} dataOwner={dataOwner}/>;
    case NOTIFICATION_EVENTS.NOTICE_PUBLISHED:
      return <SimpleContent item={notification} dataOwner={dataOwner}/>;
    case NOTIFICATION_EVENTS.FORUM_POST_REPLY:
      return <ForumContent item={notification} dataOwner={dataOwner}/>;
    default:
      return () => null;
  }
};