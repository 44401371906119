import {ApiClientService, EntityApiService} from '@dofleini/security';

class NotificationsApiService extends EntityApiService {

    getNotifications = (params, config) => {
      return ApiClientService.post(this.getPath('/search'), params, config);
    }

    update = (params, config) => {
      return ApiClientService.patch(this.getPath(`/${params._id}`), params, config);
    }
}

export default new NotificationsApiService('/ms-not/api/notifications');
