import React, {memo} from 'react';
import classNames from 'classnames';
import style from '@/layout/TopLayout/index.less';
import {useApplicationSettings} from '@/hooks/useApplicationSettings';

const Logo = () => {
  const {logo} = useApplicationSettings();

  return (
    <div className={classNames(['flex items-center', style.logoContainer])}>
      <img className={'mr-5 logo'} src={logo} alt={'logo'}/>
      {/*{searchSection}*/}
    </div>
  );

};

export default memo(Logo);
