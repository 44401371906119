import React, {memo, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useUserList} from '@/modules/admin/containers/UserEntityContainer';
import {queryCache} from 'react-query';
import {Button, Divider, Space} from 'antd';
import UserStatusSelect from '@/modules/admin/components/UserStatusSelect';
import {MailOutlined} from '@ant-design/icons';
import UserDeleteManyButton from '@/modules/admin/components/UserDeleteManyButton';
import {useDialogsContext} from '@/contexts/DialogsContext';
import {DIALOG_NAMESPACE} from '@/components/NewEmailDialog';
import {CACHE_KEY_STATS_USER} from '@/modules/admin/contants/users';

// eslint-disable-next-line react/prop-types
const UserSelectionAction = ({selectedRows, selectedRowKeys}) => {
  const {openDialog: showEmail} = useDialogsContext(DIALOG_NAMESPACE);
  const {t} = useTranslation('admin');
  const {activeKey} = useUserList();

  const sendMail = useCallback(()=>{
    showEmail({users: selectedRows});
  }, [selectedRows, showEmail]);

  const handleChange = useCallback(() => {
    queryCache.invalidateQueries(activeKey);
    queryCache.invalidateQueries(CACHE_KEY_STATS_USER);
  }, [activeKey]);

  return (
    <Space size={0}>
      <Divider size={'small'} type="vertical"/>
      <UserStatusSelect users={selectedRowKeys} onChange={handleChange}/>
      <Button size={'small'} onClick={sendMail} type="link" icon={<MailOutlined/>}>{t('sendEmail')}</Button>
      <Divider size={'small'} type="vertical"/>
      <UserDeleteManyButton users={selectedRowKeys} onDelete={handleChange}/>
    </Space>
  );
};

export default memo(UserSelectionAction);
