import PropTypes from 'prop-types';
import React, {memo, useRef} from 'react';
import {Avatar, Button, Space, Typography} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import cn from 'classnames';
import classNames from 'classnames';
import style from './index.less';
import UploadApiService from '@/modules/admin/services/UploadApiService';
import {getFromSource} from '@/utils/getImages';
import {useUpload} from '@/hooks/useUpload';
import styles from '@/assets/less/index.less';

const {Text, Title} = Typography;

const CommunityAvatar = ({editing, value, onChange, className, isSettingsPage, ...props}) => {
  const fileInput = useRef();
  const {t} = useTranslation('community');

  const {handleUpload, isLoading} = useUpload(UploadApiService.uploadImage, (data) => {
    onChange(data);
  });
  
  return (
    <div style={{maxWidth: isSettingsPage ? '25%' : '100%'}} className={styles.settingsPageFormItem}>
      <Text className={'-mt-2 mb-2 block'} type={'secondary'}>{t('community.form.image.desc')}</Text>
      {editing ?
        <Space className={classNames(className, style.mainContainer)}>
          <Avatar src={getFromSource(value)} size={79} {...props}/>
          <input
            hidden
            type={'file'}
            onChange={(ev) => handleUpload(ev.target.files)}
            ref={fileInput}
            accept={'image/*'}/>
            
          <Button
            disabled={isLoading}
            className={'ml-3'} onClick={() => fileInput.current.click()}
            icon={<DownloadOutlined/>}>{isLoading ? t('loading') : t('explore')}</Button>
        </Space>
        :
        <div className={cn(['flex items-center', className])}>
          <Avatar src={getFromSource(value.avatar)} size={60} {...props}/>
          <div className={'ml-2'}>
            <Title level={4}>{value.fullName}</Title>
            <Text type={'secondary'}>{value.username}</Text>
          </div>
        </div>
      }
      <Text className={'mt-2 block'} type={'secondary'}>{t('community.form.image.sizeDesc')}</Text>
    </div>
  );
  
};
export default memo(CommunityAvatar);

CommunityAvatar.propTypes = {
  value: PropTypes.any,
  editing: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  helperText: PropTypes.string,
  isSettingsPage: PropTypes.bool
};

CommunityAvatar.defaultProps = {
  isSettingsPage: false
};
