import React, {memo} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Tag} from 'antd';
import {USER_STATUS_APPEARANCE} from '@/modules/admin/contants/user.status';
import {useTranslation} from 'react-i18next';
import Text from 'antd/es/typography/Text';

const UserStatus = ({status, full, className, ...props}) => {
  const {t} = useTranslation('admin');
  if (!USER_STATUS_APPEARANCE[status])
    return (
      <Tag key={status} className={classNames({'w-full': full}, className, 'w-16 text-center')}>
                -
      </Tag>);

  return (
    <Text  {...props} style={{color: USER_STATUS_APPEARANCE[status].color}}>{t(`user.status.${status}`)}</Text>
  );
};

UserStatus.propTypes = {
  className: PropTypes.string,
  help: PropTypes.bool,
  full: PropTypes.bool,
  status: PropTypes.oneOf(['ACTIVE', 'DELETED', 'LOCK', 'UNVERIFIED']),
  icon: PropTypes.any,
};

UserStatus.defaultProps = {
  status: 'UNVERIFIED'
};

export default memo(UserStatus);

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Status component in a table cell
 * @param {String} status this is the value of the dataIndex of the table (we will ignored in this case)
 * */
export const renderUserStatus = (status) => {
  return (
    <UserStatus status={status}/>
  );
};
