import React, {useCallback} from 'react';
import {Menu} from 'antd';
import PropTypes from 'prop-types';

const {Item, SubMenu} = Menu;

export function renderMenu({menu, title, link, action, ...item}, index, props = {}) {
  const key = link ? `link=>${link}` : `action=>${action || index}`;
  
  // eslint-disable-next-line react/prop-types
  const disabled = props?.disabled;

  if (item.divider)
    return <Menu.Divider key={`${key}=>divider=>${index}`}/>;
    
  if (menu)
    return (
      <SubMenu disabled={disabled}
        key={`${index}-${title}`}
        title={title}
        {...item}
      >
        {menu.map((_item, _index) => renderMenu(_item, _index, props))}
      </SubMenu>
    );
    
  // eslint-disable-next-line no-unused-vars
  const {inCommunity, isBack, ...rest} = item;
  
  return (
    <Item disabled={disabled} key={key} {...rest} {...props}>
      {title}
    </Item>
  );
}

const NavMenu = ({mode, menu, theme, onClick, menuProps, subMenuProps, selectable}) => {
  
  const handleClick = useCallback((e) => {
    const [eventType, value] = e.key.split('=>');
    e[eventType] = value;
    onClick(e);
  }, [onClick]);
  
  return (
    <Menu mode={mode} theme={theme} onClick={handleClick} selectable={selectable} {...menuProps} disabled>
      {menu.map((item, index) => renderMenu(item, index, subMenuProps))}
    </Menu>
  );
};

export default NavMenu;

NavMenu.propTypes = {
  menu: PropTypes.array,
  mode: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.string,
  subMenuProps: PropTypes.object,
  menuProps: PropTypes.object,
  selectable: PropTypes.bool,
};

NavMenu.defaultProps = {
  menu: [],
  mode: 'inline',
  subMenuProps: {},
  menuProps: {},
  selectable: true
};

renderMenu.propTypes = {
  link: PropTypes.string,
  menu: PropTypes.array,
  title: PropTypes.string,
  action: PropTypes.string,
};
