import React, {memo, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useHistory, useLocation} from 'react-router-dom';
import useNavigation from '@/hooks/useNavigation';
import NavMenu from '@/layout/Menu/index';
import NavigationGroup from '@/layout/NavigationGroup/index';
import forEach from 'lodash/forEach';
import {useCommunity} from '@/modules/community/contexts/CommunityContext';
import {navigation as defaultNavigationConfig} from '@/settings';

function findActiveKeys(menuList, pathname) {
  let activeKeys = [];
  forEach(menuList, (item, index) => {
    if (item?.link && (pathname.startsWith(item?.link) || (item.include && item.include.some(e => pathname.startsWith(e))))) {
      activeKeys.push(`link=>${item?.link}`);
    }
    if (item?.menu && item?.title) {
      const activeSubKeys = findActiveKeys(item?.menu, pathname);
      if (activeSubKeys.length) {
        activeKeys = [
          `${index}-${item.title}`,
          ...activeKeys,
          ...activeSubKeys
        ];
      }
    }
  });
  return activeKeys;
}

const NavigationMenu = ({theme, mode, disabled}) => {
  const {navigationMenu, insideCommunity} = useCommunity();
  //
  let menuSidebar = useMemo(() => {
    return insideCommunity ? navigationMenu : defaultNavigationConfig;
  }, [insideCommunity, navigationMenu]);
    //
  const navigation = useNavigation(menuSidebar);
  const {push} = useHistory();

  const {pathname} = useLocation();

  const openKeys = useMemo(() => findActiveKeys(navigation, pathname), [navigation, pathname]);

  const handleClick = useCallback(({link}) => {
    push(link);
  }, [push]);

  if (Array.isArray(navigation)) {
    if (mode === 'horizontal')
      return <NavMenu
        key={pathname}
        subMenuProps={{disabled}}
        mode={mode} menu={navigation} theme={theme} onClick={handleClick}
        menuProps={{defaultSelectedKeys: openKeys, openKeys, selectable: false}}/>;

    return navigation.map((nav, key) => (
      <NavigationGroup
        subMenuProps={{disabled}}
        mode={mode} key={`${pathname}-${key}`} index={key} {...nav} theme={theme} onClick={handleClick}
        menuProps={{defaultSelectedKeys: openKeys, defaultOpenKeys: openKeys, selectable: false}}/>
    ));
  }

  return (
    <NavMenu
      key={pathname}
      subMenuProps={{disabled}}
      mode={mode} menu={navigation.menu} theme={theme} onClick={handleClick}
      menuProps={{defaultSelectedKeys: openKeys, selectable: false}}/>
  );
};

NavigationMenu.propTypes = {
  mode: PropTypes.string,
  theme: PropTypes.oneOf(['dark', 'light']),
  disabled: PropTypes.bool,
};

NavigationGroup.propTypes = {
  mode: PropTypes.string,
  theme: PropTypes.oneOf(['dark', 'light']),
};

NavigationMenu.defaultProps = {
  mode: 'inline',
  theme: 'dark',
};

NavigationGroup.defaultProps = {
  mode: 'inline'
};

export default memo(NavigationMenu);
