import React, {memo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import UserAvatar from '@/components/UserAvatar';

const User = ({user, className, textClassName}) => {
  return (
    <div className={classNames(className, 'flex flex-row items-center')}>
      <UserAvatar user={user}/>
      <span className={classNames(textClassName, 'ml-2')}>{user?.fullName}</span>
    </div>
  );

};

export default memo(User);

User.propTypes = {
  className: PropTypes.string,
  textClassName: PropTypes.string,
  user: PropTypes.object
};
