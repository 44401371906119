import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Form, DatePicker, Button} from 'antd';
import FilterContent from '../../FilterContent';
import {getTodayRange, getThisWeek, getLastSevenDays, getThisMonth, getLastThirtyDays, getLastMonth} from './utils';
import isNil from 'lodash/isNil';
import moment from 'moment';

const {RangePicker} = DatePicker;

const DateSelector = ({t, value, onChange}) => {

  return (<>
    <div className="flex flex-wrap p-1">
      <Button className="m-1 px-2" onClick={() => onChange(getTodayRange())}>
        {t('dateFilter.today')}
      </Button>
      <Button className="m-1 px-2" onClick={() => onChange(getThisWeek())}>
        {t('dateFilter.thisWeek')}
      </Button>
      <Button className="m-1 px-2" onClick={() => onChange(getLastSevenDays())}>
        {t('dateFilter.lastSevenDays')}
      </Button>
      <Button className="m-1 px-2" onClick={() => onChange(getThisMonth())}>
        {t('dateFilter.thisMonth')}
      </Button>
      <Button className="m-1 px-2" onClick={() => onChange(getLastThirtyDays())}>
        {t('dateFilter.lastThirtyDays')}
      </Button>
      <Button className="m-1 px-2" onClick={() => onChange(getLastMonth())}>
        {t('dateFilter.lastMonth')}
      </Button>
    </div>
    <div className="p-2">
      <RangePicker className="w-full" value={value} onChange={onChange}/>
    </div>
  </>);
};

DateSelector.propTypes = {
  t: PropTypes.func,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export const DateRangeComponent = ({filter, t, applyFilter}) => {

  return (
    <div style={{maxWidth: '20rem'}}>
      <Form onFinish={({value}) => {
        if (value) {
          const [from, to] = value;

          applyFilter({
            field: filter.field,
            type: 'RANGE',
            from: from.startOf('date').toISOString(),
            to: to.endOf('date').toISOString()
          });
        }
      }}>
        <FilterContent t={t}>
          <Form.Item name="value" className="m-0">
            <DateSelector t={t}/>
          </Form.Item>
        </FilterContent>
      </Form>
    </div>
  );
};

DateRangeComponent.propTypes = {
  filter: PropTypes.object,
  t: PropTypes.func,
  applyFilter: PropTypes.func,
};

export const ValueDateRange = ({filterValue}) => {
  const valueContent = useMemo(() => {
    const {to, from} = filterValue;
    if (!isNil(from) && !isNil(to)) {
      return `${moment(from).format('LL').toString()} - ${moment(to).format('LL').toString()}`;
    }

    return null;
  }, [filterValue]);
  return <div className="flex">{
    valueContent
  }</div>;
};

ValueDateRange.propTypes = {
  filter: PropTypes.object,
  t: PropTypes.func,
  filterValue: PropTypes.any,
};