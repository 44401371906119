import React, {memo} from 'react';
import MainRouter from './MainRouter';
import MainLayout from '@/layout/MainLayout';
import {CommunityProvider} from '@/modules/community/contexts/CommunityContext';
import 'antd/es/slider/style';
import NewEmailDialog from '@/components/NewEmailDialog';

const MainApp = () => {
  return (
    <CommunityProvider>
      <MainLayout>
        <MainRouter/>
        <NewEmailDialog/>
      </MainLayout>
    </CommunityProvider>
  );
};

export default memo(MainApp);
