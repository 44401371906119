import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Form, Input} from 'antd';
import FilterContent from '../../FilterContent';
import classNames from 'classnames';
import map from 'lodash/map';
import find from 'lodash/find';
import compose from 'lodash/fp/compose';
import filterFP from 'lodash/fp/filter';
import mapFP from 'lodash/fp/map';
import {SearchOutlined} from '@ant-design/icons';
import findMatch from '@/utils/findMatch';
import {TermFilter} from '@dofleini/query-builder';
import isEmpty from 'lodash/isEmpty';

export const EnumComponent = ({filter, t, applyFilter}) => {
  const [searchText, setSearchText] = useState('');

  const options = useMemo(() => filter.options || [], [filter.options]);

  const filteredOptions = useMemo(() => compose(
    filterFP(({label}) => findMatch(searchText, label)),
    mapFP(({label, ...rest}) => ({label: t(label), ...rest})),
  )(options), [options, searchText, t]);

  const onFinish = useCallback(({value: values}) => {
    if (values && !isEmpty(values)) {
      applyFilter(
        {
          'type': 'OR',
          'filters': map(values, value => new TermFilter({field: filter.field, value}).toQuery())
        }
      );
    } else {
      applyFilter();
    }
  }, [applyFilter, filter.field]);

  return (<>
    {
      filter.search &&
      <div className="p-2">
        <Input suffix={<SearchOutlined/>} value={searchText} onChange={({target}) => setSearchText(target?.value)}/>
      </div>
    }

    <Form onFinish={onFinish}>
      <FilterContent t={t}>
        <Form.Item name="value" className="m-0">
          <Checkbox.Group className="w-full">
            <div className="w-full flex flex-col max-h-300px overflow-y-auto">
              {
                filteredOptions.map(({value, label}) => (
                  <Checkbox className={classNames('mx-0 my-px p-2')} key={value} value={value}>
                    {label}
                  </Checkbox>
                ))
              }
            </div>
          </Checkbox.Group>
        </Form.Item>
      </FilterContent>
    </Form>
  </>);
};

EnumComponent.propTypes = {
  filter: PropTypes.object,
  t: PropTypes.func,
  applyFilter: PropTypes.func,
};

export const ValueEnum = ({filterValue, t, filter}) => {
  const options = useMemo(() => map(
    filterValue?.filters,
    ({value}) => {
      const item = find(filter?.options, {value});
      return <div className="px-1" key={item?.label}>{t(item?.label)}</div>;
    }), [filter, t, filterValue]);

  return <div className="flex">{options}</div>;
};

ValueEnum.propTypes = {
  filter: PropTypes.object,
  t: PropTypes.func,
  filterValue: PropTypes.any,
};
