import React, {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {Layout} from 'antd';
import PageHeader from '@/components/PageHeader';
import PropTypes from 'prop-types';

const PageContentContext = createContext();

function PageContent({children}) {
  const [title, setTitle] = useState();
  const [breadcrumbCustomMap, setBreadcrumbCustomMap] = useState({});
  
  const addBreadcrumbCustomMap = useCallback((key, value) => {
    if(!breadcrumbCustomMap[key]){
      breadcrumbCustomMap[key] = value;
      setBreadcrumbCustomMap(Object.assign({}, breadcrumbCustomMap));
    }
  }, [breadcrumbCustomMap]);
  
  return (
    <PageContentContext.Provider value={{
      setBreadcrumbCustomMap,
      setTitle,
      addBreadcrumbCustomMap
    }}>
      <Layout>
        <Layout.Content className={'m-3 lg:m-5'}>
          <PageHeader title={title} className={'mb-3 lg:mb-5'} customMap={breadcrumbCustomMap}/>
          {children}
        </Layout.Content>
      </Layout>
    </PageContentContext.Provider>
  );
}

function usePageContent(options = {}) {
  const context = useContext(PageContentContext);
  if (context === undefined) {
    throw new Error('usePageContent must be used within a PageContentProvider');
  }
  const {title} = options;
  const {
    setTitle, setBreadcrumbCustomMap, addBreadcrumbCustomMap
  } = context;
  
  useEffect(() => {
    if (title)
      setTitle(title);
    return () => setTitle('');
  }, [setTitle, title]);
  
  return {
    setTitle,
    setBreadcrumbCustomMap,
    addBreadcrumbCustomMap
  };
}

PageContent.propTypes = {
  children: PropTypes.any
};


export {PageContent, usePageContent};
