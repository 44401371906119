import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {authentication} from '@/settings';
import {AuthLayout} from '@dofleini/auth-layout';
import style from './index.less';
import {useLocation} from 'react-router-dom';
import {ReactComponent as Login} from '@/assets/auth/login.svg';
import {ReactComponent as Signup} from '@/assets/auth/signup.svg';
import {ReactComponent as Recover} from '@/assets/auth/recover.svg';
import {ReactComponent as Logo} from '@/assets/images/logo/logo-normal.svg';
import {ReactComponent as AppLink} from '@/assets/auth/signup.svg';

const imageByRoute = [
  {
    path: '/signin',
    Component: <Login className={style.singIn}/>
  },
  {
    path: '/app',
    Component: <AppLink className={style.appLink}/>
  },
  {
    path: '/signup',
    Component: <Signup className={style.singUp}/>
  },
  {
    path: '/recovery_password',
    Component: <Recover className={style.singIn}/>
  },
  {
    path: '/reset_password',
    Component: <Recover className={style.singIn}/>
  }, {
    path: '/verify',
    Component: <Recover className={style.singIn}/>
  }
];


const Right = () => {
  const {pathname} = useLocation();
  const Image = imageByRoute.find((im) => pathname.indexOf(im.path) !== -1) || imageByRoute[0];
  return (
    <div>
      <Logo width={'144px'} className={style.logo} alt={'Jamii'} fill={'#000'}/>
      {Image.Component}
    </div>
  );
};

const AuthenticationAppLayout = ({children}) => {
  return (
    <AuthLayout {...authentication} className={'global-layout-container'} rightClass={'place-item-center'}
      rightComponent={<Right/>}>
      <div>
        {children}
      </div>
    </AuthLayout>
  );
};
AuthenticationAppLayout.propTypes = {
  children: PropTypes.any,
};

export default memo(AuthenticationAppLayout);
