import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Form} from 'antd';
import FilterContent from '@/components/TableFilters/components/FilterContent';
import classNames from 'classnames';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import {TermFilter} from '@dofleini/query-builder';

const BOOL_ENUM = {
  TRUE: 'true',
  FALSE: 'false',
};

export const BoolComponent = ({t, applyFilter, filter}) => {
  const options = useMemo(() => map(BOOL_ENUM, value => ({value, label: filter[`${value}Text`]})), [filter]);

  const onFinish = useCallback(({value: values}) => {
    if (isEmpty(values))
      return;

    const filters = map(values, value => {
      switch (value) {
        case BOOL_ENUM.TRUE:
          return new TermFilter({field: filter.field, value: true}).toQuery();
        case BOOL_ENUM.FALSE:
        default:
          return new TermFilter({field: filter.field, value: false}).toQuery();
      }
    });
    return applyFilter({type: 'OR', filters});
  }, [applyFilter, filter.field]);

  return (
    <Form onFinish={onFinish}>
      <FilterContent t={t}>
        <Form.Item name="value" className="m-0">
          <Checkbox.Group className="w-full">
            <div className="w-full flex flex-col max-h-300px">
              {
                options.map(({value, label}) => (
                  <Checkbox className={classNames('mx-0 my-px p-2')} key={value} value={value}>
                    {t(label)}
                  </Checkbox>
                ))
              }
            </div>
          </Checkbox.Group>
        </Form.Item>
      </FilterContent>
    </Form>
  );
};

BoolComponent.propTypes = {
  filter: PropTypes.object,
  t: PropTypes.func,
  applyFilter: PropTypes.func,
};

export const ValueBool = ({filterValue, t, filter}) => {
  const options = useMemo(() => map(filterValue.filters,
    ({value}) => {
      const text = filter[`${String(value)}Text`];
      return <div className="px-1" key={`${filter?.field}-${value}`}>{t(text)}</div>;
    }), [filterValue.filters, filter, t]);

  return <div className="flex">{options}</div>;
};

ValueBool.propTypes = {
  filter: PropTypes.object,
  t: PropTypes.func,
  filterValue: PropTypes.any,
};
