import PropTypes from 'prop-types';
import React, {memo, useCallback, useEffect, useMemo} from 'react';
import {useGetRoles} from '@/modules/admin/hooks/useRol';
import {Select} from 'antd';
import {useTranslation} from 'react-i18next';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import {createMapEntityToOptions} from '@/utils/createMapEntityToOptions';

const mapOptionsFn = createMapEntityToOptions();

const RolSelector = ({value, onChange, setDefaultRole}) => {
  const {t} = useTranslation('admin');

  const {isLoading, data} = useGetRoles();

  const mappedValue = useMemo(() => map(value, (role) => {
    return role?._id || role?.role?._id || role?.role;
  }), [value]);

  const options = useMemo(() => {
    if (isEmpty(data)) {
      return map(value, role => mapOptionsFn(role?.role || {}));
    } else {
      return map(data, mapOptionsFn);
    }
  }, [data, value]);

  useEffect(() => {
    if (map(data, mapOptionsFn)?.length) {
      setDefaultRole(map(data, mapOptionsFn)[0].value);
    }
  }, [data, setDefaultRole]);

  const handleChange = useCallback((values) => {
    onChange(map(values, _id => ({_id})));
  }, [onChange]);

  return (
    <Select
      className="w-full"
      loading={isLoading}
      mode="multiple"
      allowClear
      maxTagCount={1}
      placeholder={t('user.form.defaultRol')}
      options={options}
      onChange={handleChange}
      value={mappedValue.length ? mappedValue : [data && data[0]._id]}
    />
  );
};

export default memo(RolSelector);

RolSelector.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  setDefaultRole: PropTypes.func
};

RolSelector.defaultProps = {
  value: [],
  setDefaultRole: () => {}
};

// eslint-disable-next-line react/prop-types
export const renderRolSelector = props => {
  return <RolSelector {...props}/>;
};
