import PropTypes from 'prop-types';
import React, {memo} from 'react';
import {Button, Form, Input, Select} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {ON_BOARDING_TYPES} from '@/modules/admin/contants/boardingTypes';
import Text from 'antd/es/typography/Text';
import {useTranslation} from 'react-i18next';
import {useResponsive} from '@/contexts/ResponsiveContext';

const {Option} = Select;

const OnBoardingFields = ({title, translation, description, isSettingsPage}) => {
  const {t} = useTranslation(translation || 'community');
  const {isMobile} = useResponsive();
  
  return (
    <div style={{maxWidth: isSettingsPage && !isMobile ? '50%' : '100%'}}>
      {!!title && <Text className={'block -mt-2 mb-3 font-bold'} type={'primary'}>{title}</Text>}
      <Text className={'block -mt-2 mb-3'} type={'secondary'}>{t(description || 'onBoardFields.desc')}</Text>
      <Form.List name="onBoardFields">
        {(fields, {add, remove}) => (
          <>
            {fields.map(field => (
              <div key={field.key} style={{display: 'flex', marginBottom: 8}} align="baseline"
                className="w-full md:flex-row flex-col items-center">
                <Form.Item
                  {...field}
                  className="mb-4 md:mb-0 w-full md:mr-3"
                  name={[field.name, 'field']}
                  fieldKey={[field.fieldKey, 'field']}
                >
                  <Input placeholder={t('onBoardFields.field')}/>
                </Form.Item>
                <Form.Item
                  className="mb-4 md:mb-0 w-full md:mr-3"
                  {...field}
                  name={[field.name, 'type']}
                  fieldKey={[field.fieldKey, 'type']}
                >
                  <Select placeholder={t('onBoardFields.type')}>
                    {
                      Object.keys(ON_BOARDING_TYPES).map((type) => (
                        <Option key={ON_BOARDING_TYPES[type]} value={ON_BOARDING_TYPES[type]}>
                          {t(`onBoardFields.${ON_BOARDING_TYPES[type]}`)}
                        </Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item
                  className="mb-2 md:mb-0 w-full md:mr-3"
                  {...field}
                  name={[field.name, 'label']}
                  fieldKey={[field.fieldKey, 'label']}
                >
                  <Input placeholder={t('onBoardFields.label')}/>
                </Form.Item>
                <div>
                  <span className={'md:hidden mr-1'}>{t('remove')}</span>
                  <MinusCircleOutlined onClick={() => remove(field.name)}/>
                </div>
              </div>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined/>}/>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};

export default memo(OnBoardingFields);

OnBoardingFields.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  translation: PropTypes.string,
  isSettingsPage: PropTypes.bool
};

OnBoardingFields.defaultProps = {
  isSettingsPage: false
};
