import UserAvatar from '@/components/UserAvatar/index';
import React from 'react';
import {Badge, Typography} from 'antd';
import {getUserFullName} from '@/utils/userFullName';
import style from './index.less';
import cn from 'classnames';
const {Text, Title} = Typography;

export default (value, form, initialData = {}) => {
  const updatedData = form.getFieldsValue(true) || {};
  const user = {
    ...initialData,
    ...updatedData,
  };
  const fullName = getUserFullName(user);
  
  return (
    <div className={cn([style.avatarContainer, 'py-2'])}>
      <Badge color={user.connected ? '#1BCC44' : '#D9D9D9'}>
        <UserAvatar src={value} user={user} size={66}/>
      </Badge>
      <div className={'lg:ml-0 ml-4'}>
        <Title className={'mt-2 mb-0'} level={4}>{fullName}</Title>
        <Text type={'secondary'}>{user?.email}</Text>
      </div>
    </div>
  );
};
