import {useCallback, useEffect, useState} from 'react';
import {queryCache, usePaginatedQuery} from 'react-query';
import {ApiClientService} from '@dofleini/security';

export const createPaginationHook = (name, service) => {
  let asyncFn;
  
  switch (typeof service) {
    case 'function':
      asyncFn = service;
      break;
    case 'string':
      asyncFn = params => ApiClientService.post(service, params);
      break;
    default:
      throw new TypeError('Invalid type of service');
  }
  
  return (search, filters, config = {}, queryConfig = {}) => {
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(config.size || 10);
    const [sort, setSort] = useState(config.sort || null);
    
    const fetchData = useCallback(async (key, page = 1) => {
      const {data, headers} = await asyncFn({page, size, search, filters, sort});
      const totalPages = Number(headers['x-total-count'] || 0);
      const hasMore = data.length === size;
      return {data, totalPages, hasMore};
    }, [size, search, filters, sort]);
    
    useEffect(() => {
      setPage(1);
    }, [search, filters]);
    
    const {
      status,
      resolvedData,
      latestData,
      error,
      isFetching,
      isLoading,
      isError,
      refetch
    } = usePaginatedQuery([name, page, search, size, filters], fetchData, queryConfig);
    
    useEffect(() => {
      if (latestData?.hasMore) {
        queryCache.prefetchQuery([name, page + 1, search, size, filters], fetchData);
      }
    }, [latestData, page, size, search, filters, fetchData]);
    
    
    return {
      page,
      status,
      resolvedData,
      ...latestData,
      error,
      isFetching,
      isLoading,
      isError,
      refetch,
      setPage,
      setSize,
      setSort,
      activeKey: [name, page, search, size, filters]
    };
    // Prefetch the next page!
  };
};
