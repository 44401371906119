import {useQuery} from 'react-query';
import RolApiService from '@/modules/admin/services/RolApiService';

export const useGetRoles = () => {
  const {isLoading, isError, error, data} = useQuery('user-roles', async () => {
    const {data} = await RolApiService.getRoles({sort: {createdAt: 1}});
    return (data);
  });
  return {isLoading, isError, error, data};
};
