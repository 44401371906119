import PropTypes from 'prop-types';
import React, {memo} from 'react';
import {Drawer, Modal} from 'antd';
import classNames from 'classnames';
import style from './index.less';
import HeaderDrawerView from '@/components/HeaderFormView/index.v2';
import {useResponsive} from '@/contexts/ResponsiveContext';

export const TYPE_CONTAINER = {
  MODAL: 'MODAL',
  DRAWER: 'DRAWER'
};

const FloatContainer = ({type, onClose, children, title, footer, customClass, ...props}) => {
  const {isMobile} = useResponsive();
  const currentType = isMobile ? TYPE_CONTAINER.DRAWER : type;
  
  switch (currentType) {
    case TYPE_CONTAINER.MODAL:
      return <Modal centered onCancel={onClose} footer={footer} title={title} {...props}
        className={customClass}>
        {children}
      </Modal>;
    case TYPE_CONTAINER.DRAWER:
    default:
      return <Drawer
        className={classNames(style.drawerContainer, {[style.fullMobile]: isMobile}, customClass)}
        onClose={onClose}
        {...props}
        title={
          <HeaderDrawerView
            title={title} extras={footer} onClose={onClose}/>
        } closable={false}>
        <div className={'p-6'}>
          {children}
        </div>
      </Drawer>;
  }
};

export default memo(FloatContainer);

FloatContainer.propTypes = {
  children: PropTypes.any,
  footer: PropTypes.any,
  onClose: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
  customClass: PropTypes.string
};
