import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {MessageTwoTone} from '@ant-design/icons';
import {useUser} from '@dofleini/security';
import {useTranslation} from 'react-i18next';
import {getUserFullName} from '@/utils/userFullName';
import {NOTIFICATION_EVENTS} from '@/constants/notifications';
import ReactHtmlParser from 'react-html-parser';
import {useGetOneUser} from '@/modules/admin/hooks/useUser';

export const ForumContent = ({item, dataOwner}) => {
  const {t} = useTranslation('notifications');
  const {user} = useUser();
  
  const userName = useMemo(() => {
    return dataOwner?._id === user._id ? t('me') : getUserFullName(item?.owner);
  }, [dataOwner, item.owner, t, user._id]);
  
  const {data: ownerReplied} = useGetOneUser({id: item?.body?.repliedBy});
  
  const repliedBy = useMemo(() => {
    return getUserFullName(ownerReplied?.data);
  }, [ownerReplied]);
  
  const titleKey = useMemo(() => {
    return dataOwner?._id === user._id ? 'titleMe' : 'title';
  }, [dataOwner, user._id]);
  
  return (
    <span className="text-base">
      {`${userName}`}
      <span className="px-1 font-normal opacity-75">
        {t(`notificationType.${item?.type}.${titleKey}`)} <span className="font-semibold">{repliedBy}</span>
      </span>
    </span>
  );
};

ForumContent.propTypes = {
  item: PropTypes.object,
  dataOwner: PropTypes.object
};

export const DescriptionForumContent = ({item}) => {
  
  const switchIcon = useCallback(type => {
    if (type) {
      switch (type) {
        case NOTIFICATION_EVENTS.FORUM_POST_REPLY:
          return <div><MessageTwoTone className="mr-2"/></div>;
        default:
          return null;
      }
    }
  }, []);
  
  const html = useMemo(() => {
    const regHtml = new RegExp('</?\\w+((\\s+\\w+(\\s*=\\s*(?:".*?"|\'.*?\'|[\\^\'">\\s]+))?)+\\s*|\\s*)/?>');
    
    if (!regHtml.test(item?.body?.body))
      return ReactHtmlParser(item?.body?.body);
      
    return item?.body?.body;
  }, [item]);
  
  return (
    <div className="flex items-start justify-start">
      {switchIcon(item?.event)}
      <div className="flex font-normal text-sm font-semibold italic text-black">
        {item?.payload?.body?.title}
      </div>
      <div className="flex" contentEditable='true' dangerouslySetInnerHTML={{__html: html}}/>
    </div>
  );
};

DescriptionForumContent.propTypes = {
  item: PropTypes.object
};
