import React from 'react';
import Icon, {
  AuditOutlined, DashboardOutlined,
  ExportOutlined,
  HomeOutlined,
  // MailOutlined,
  SafetyCertificateOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  UserOutlined
} from '@ant-design/icons';
import {useHandleClick} from '@/components/UserOptions/hooks';
import {USER_ACTION_TYPE} from '@/components/UserOptions/constants';
import {ReactComponent as MembersIcon} from '../assets/icons/people-outline.svg';
import {ReactComponent as NoticeIcon} from '../assets/icons/notice.svg';
import {ReactComponent as EventIcon} from '../assets/icons/group.svg';
import {ReactComponent as SurveyIcon} from '../assets/icons/survey.svg';
import {ReactComponent as SettingsIcon} from '../assets/icons/settings-2-outline.svg';
import {ReactComponent as ForumIcon} from '../assets/icons/message-circle-outline.svg';
import IconNotifications from '@/components/NotificationDrawer/components/Icon';

/**
 * Navigation define all the routes and link that
 * will be render in the sidebar or navbar(depending of the layout)
 *
 * It can be an
 * {Array} Use array when yo have group sections, like Main, Admin , etc
 * Ex: [{title:'name', menu:[...],}, ....]
 *
 * {Object} Use object when yo don't have section , it is just one,
 * Ex: {menu: [.....]}
 * */
export const navigation = [
  {
    title: 'general',
    menu: [
      {
        title: 'home',
        link: '/dashboard',
        icon: <HomeOutlined/>
      },
      {
        title: '/admin/communities',
        link: '/admin/communities',
        permissions: ['COMMUNITY:READ'],
        icon: <AuditOutlined/>
      },
      {
        title: '/admin/users',
        link: '/admin/users',
        permissions: ['ADMIN'],
        icon: <UsergroupAddOutlined/>,
      },
      {
        title: '/admin/permissions',
        link: '/admin/permissions',
        permissions: ['ADMIN'],
        icon: <SafetyCertificateOutlined/>,
      },
      {
        title: 'settings',
        link: '/admin/settings',
        permissions: ['SETTING:WRITE'],
        icon: <SettingOutlined/>
      }
    ]
  }
];

export const communityNavigation = (communityID) => [
  {
    title: 'general',
    menu: [
      {
        title: 'home',
        link: '/dashboard',
        permissions: ['ADMIN'],
        icon: <HomeOutlined/>,
        isBack: true
      },
      {
        title: '/dashboard',
        link: `/${communityID}/dashboard`,
        icon: <DashboardOutlined />
      },
      {
        title: '/members',
        link: `/${communityID}/members`,
        permissions: ['COMMUNITY:READ'],
        icon: <Icon component={MembersIcon} style={{fontSize: '1.1rem'}}/>
      },
      {
        title: '/notices',
        link: `/${communityID}/notices`,
        permissions: ['NOTICE:READ'],
        icon: <Icon component={NoticeIcon} style={{fontSize: '1.1rem'}}/>
      },
      {
        title: '/events',
        link: `/${communityID}/events`,
        permissions: ['EVENT:READ'],
        icon: <Icon component={EventIcon} style={{fontSize: '1.1rem'}}/>
      },
      {
        title: '/surveys',
        link: `/${communityID}/surveys`,
        permissions: ['SURVEY:READ'],
        icon: <Icon component={SurveyIcon} style={{fontSize: '1.1rem'}}/>
      },
      {
        title: '/forums',
        link: `/${communityID}/forums`,
        permissions: ['FORUM:READ'],
        icon: <Icon component={ForumIcon} style={{fontSize: '1.1rem'}}/>
      },
      {
        title: '/settings',
        link: `/${communityID}/settings`,
        permissions: ['COMMUNITY:WRITE'],
        icon: <Icon component={SettingsIcon} style={{fontSize: '1.1rem'}}/>
      },
    ]
  }
];

export const userOptions = {

  options: [
    {
      title: 'notifications',
      action: 'NOTIFICATIONS',
      icon: <IconNotifications />
    }
  ],
  userActions: {
    viewType: USER_ACTION_TYPE.LIST,
    showInfo: true,
    options: [
      {
        title: 'profile',
        action: 'PROFILE',
        icon: <UserOutlined/>,
      },
      {

        title: 'logout', action: 'LOGOUT',
        icon: <ExportOutlined/>,
      }
    ],
  },
  useHandleClick
};
