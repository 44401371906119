import AdminModule from '@/modules/admin';
import DashBoardModule from '@/modules/dashboard';
import CommunityModule from '@/modules/community';

export default {
  Home: {
    exact: true,
    path: '/dashboard',
    component: DashBoardModule
  },
  User: {
    exact: false,
    path: '/admin',
    component: AdminModule
  },
  Communities: {
    exact: false,
    path: '/:communityID',
    component: CommunityModule
  },
};
