import {createGetOneHook} from '@/utils/createGetOneHook';
import {createFieldsHook} from '@/utils/createFieldsHook';
import UserApiService from '@/modules/admin/services/UserApiService';
import {CACHE_KEY_USER, fields} from '@/modules/admin/contants/users';
import {useLocation} from 'react-router-dom';
import {useMemo} from 'react';

export const useGetOneUser = createGetOneHook(
  UserApiService,
  'id',
  CACHE_KEY_USER,
);

const useFieldsBase = createFieldsHook('admin', fields);

export const useFields = (isViewMode) => {
  const fields = useFieldsBase(isViewMode);
  const {pathname} = useLocation();

  const isCreate = useMemo(() => pathname.endsWith('/create'), [pathname]);

  return useMemo(() => isCreate ? fields.filter(({key}) => key !== 'status') : fields, [fields, isCreate]);
};
