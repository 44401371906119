import {ApiClientService, EntityApiService} from '@dofleini/security';
import {authentication} from '@/settings';

class UploadApiService extends EntityApiService {
    uploadImage = (params, onProgress) => {
      const config = {
        headers: {'content-type': 'multipart/form-data'},
        onUploadProgress: onProgress
      };
      return ApiClientService.post(this.getPath('/image'), params, config);
    };
    
    uploadFile = ({file, onProgress}) => {
      const config = {
        headers: {'content-type': 'multipart/form-data'},
        onUploadProgress: onProgress
      };
      return ApiClientService.post(this.getPath('/upload'), file, config);
    };
    
}

export default new UploadApiService(`${authentication.path}/api/files`);
