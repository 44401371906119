import React, {memo} from 'react';
import PropTypes from 'prop-types';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';

const Editor = ({value, onChange, autoFocus, ...props}) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      {...props}
      data={value}
      onInit={(editor) => {
        if(value){
          editor.setData(value);
        }
        if (autoFocus) {
          editor.editing.view.focus();
        }
      }}
      onChange={(event, editor) => {
        onChange && onChange({target: {value: editor.getData()}});
      }}

    />
  );
};

export default memo(Editor);

Editor.propTypes = {
  value: PropTypes.any,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool
};

Editor.defaultProps = {
  autoFocus: false
};
