import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import {List} from 'antd';
import NotificationItem from '@/components/NotificationDrawer/components/NotificationItem';
import {Skeleton} from 'antd/es';

const createRenderItem = ({updateNotification}) => (item) => {
  return (
    <NotificationItem
      key={`notification-${item?._id}`}
      item={item}
      updateNotification={updateNotification}/>
  );
};

const ListNotification = ({isLoading, notifications, updateNotification}) => {
  const rowRender = useMemo(() => createRenderItem({updateNotification}), [updateNotification]);

  if (isLoading)
    return (
      <div className="m-4">
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </div>
    );

  return <List
    itemLayout="vertical"
    size="large"
    renderItem={rowRender}
    dataSource={notifications}/>;
};

ListNotification.propTypes = {
  isLoading: PropTypes.bool,
  notifications: PropTypes.array,
  updateNotification: PropTypes.func,
};

ListNotification.defaultProps = {
  notifications: [],
  updateNotification: () => null
};

export default memo(ListNotification);