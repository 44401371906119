import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox, Dropdown, Input, Menu, Space, Tooltip} from 'antd';
import {FilterOutlined, PlusOutlined, SettingOutlined} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import {TABLE_SIZE, useTableGrid} from '@/contexts/tableGridContact';
import {useFilter} from '@/contexts/FilterContext';
import {useResponsive} from '@/contexts/ResponsiveContext';
import {Link} from 'react-router-dom';

//filter
import {TableFiltersProvider} from '@/components/TableFilters/contexts/TableFiltersContext';
import FilterArea from '@/components/TableFilters/components/FilterArea';
import FilterDropdown from '@/components/TableFilters/components/FilterDropdown';
import PermissionCheck from '@/components/PermissionCheck';

const {SubMenu} = Menu;

const TableMenu = () => {
  const {handleChange, size, visibleColumns, handleDeletedCols, toggleAllHidden, columns} = useTableGrid();
  const {t} = useTranslation('table');
  const someColumnHidden = visibleColumns.length > 0 && visibleColumns.length !== columns.length;
  const allVisible = visibleColumns.length === columns.length;
  
  const menu = (
    <Menu onClick={handleChange}>
      <SubMenu title={t('tableSize')}>
        <Menu.Item key={TABLE_SIZE.big}>
          <div className={'w-40'}>
            <Checkbox checked={size === TABLE_SIZE.big}> {t('big')}</Checkbox>
          </div>
        </Menu.Item>
        <Menu.Item key={TABLE_SIZE.middle}>
          <div className={'w-40'}>
            <Checkbox checked={size === TABLE_SIZE.middle}> {t('medium')}</Checkbox>
          </div>
        </Menu.Item>
        <Menu.Item key={TABLE_SIZE.small}>
          <div className={'w-40'}>
            <Checkbox checked={size === TABLE_SIZE.small}> {t('small')}</Checkbox>
          </div>
        </Menu.Item>
      </SubMenu>
      <SubMenu title={t('showHideColumns')}>
        <div className={'pt-1'}>
          <div className={'py-1 px-3 border-0 border-b border-solid border-gray-300 mb-3'}>
            <Space>
              <Checkbox
                checked={allVisible}
                indeterminate={someColumnHidden}
                onChange={() => toggleAllHidden(someColumnHidden ? true : !allVisible)}>{t('showColumns')}</Checkbox>
              <Button type={'link'} onClick={() => toggleAllHidden(true)} disabled={allVisible}>
                {t('reset')}
              </Button>
            </Space>
          </div>
          {
            columns
              .filter((c) => c.dataIndex !== 'actions')
              .map(c => <div key={`col-${c.dataIndex}`}
                className={'py-1 px-3 hover:bg-item-hover-bg'}>
                <Checkbox checked={c.visible} onChange={() => handleDeletedCols(c.dataIndex)}>
                  {c.title || c.dataIndex}
                </Checkbox>
              </div>)
          }
        </div>
      </SubMenu>
    </Menu>
  );
  
  return <div className={'hidden lg:block'}>
    <Dropdown overlay={menu} trigger={['click']}>
      <Tooltip title={t('tableSettings')}>
        <Button icon={<SettingOutlined/>} type={'text'}/>
      </Tooltip>
    </Dropdown>
  </div>;
};

const AddButton = ({onAdd, addLabel}) => {
  return (<Button type="primary" onClick={onAdd} icon={<PlusOutlined/>}>
    <div className={'ml-2 hidden md:inline'}>{addLabel}</div>
  </Button>
  );
};
const AddButtonConfig = ({useCreatePath, route, onAdd, addLabel}) => {
  if (useCreatePath && route)
    return <Link to={`${route}/create`}>
      <AddButton addLabel={addLabel}/>
    </Link>;
  if (onAdd)
    return <AddButton addLabel={addLabel} onAdd={onAdd}/>;
  return <></>;
};

const TableHeader = ({
  title, canSearch, route, useCreatePath,
  addLabel, onAdd, searchPlaceholder,
  extras, hasTable, canAdd,
  translation, filters, titleExtras, entityName
}) => {
  const {setSearch} = useFilter();
  const {isMobile} = useResponsive();
  const {t} = useTranslation('table');
  
  return (
    <TableFiltersProvider filters={filters}>
      <div className={'flex flex-col md:flex-row md:items-center mb-5 min-h-12'}>
        <h1 className={'font-semibold text-3xl mb-0'}>{title}</h1>
        {titleExtras}
        <FilterArea translation={translation}/>
        <Space size={isMobile ? 'small' : 'middle'}>
          <FilterDropdown translation={translation}>
            <Button icon={<FilterOutlined/>}>{!isMobile && t('filters')}</Button>
          </FilterDropdown>
          {canSearch &&
                    <Input.Search
                      className="min-w-48"
                      allowClear
                      placeholder={searchPlaceholder || t('searchPlaceholder')}
                      onSearch={setSearch}
                      enterButton/>
          }
          {
            canAdd && <PermissionCheck permissions={`${entityName}:WRITE`}>
              <AddButtonConfig onAdd={onAdd} addLabel={addLabel} route={route}
                useCreatePath={useCreatePath}/>
            </PermissionCheck>
          }
          {extras}
          {hasTable && <TableMenu/>}
        </Space>
      </div>
    </TableFiltersProvider>
  );
};


export default memo(TableHeader);

TableHeader.propTypes = {
  addLabel: PropTypes.string,
  addText: PropTypes.string,
  canSearch: PropTypes.bool,
  extras: PropTypes.node,
  titleExtras: PropTypes.node,
  filters: PropTypes.any,
  hasTable: PropTypes.bool,
  onAdd: PropTypes.func,
  route: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  title: PropTypes.string,
  translation: PropTypes.string,
  translationPrefix: PropTypes.string,
  entityName: PropTypes.string,
  canAdd: PropTypes.bool,
  useCreatePath: PropTypes.bool
};

TableHeader.defaultProps = {
  addText: 'Añadir'
};

AddButtonConfig.propTypes = {
  addLabel: PropTypes.string,
  onAdd: PropTypes.func,
  route: PropTypes.string,
  useCreatePath: PropTypes.bool
};

AddButton.propTypes = {
  addLabel: PropTypes.string,
  onAdd: PropTypes.func
};
