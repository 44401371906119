import React from 'react';
import CommunityColorSelect from '@/modules/community/components/CommunityColorSelect';
import CommunityAvatar from '@/modules/community/components/CommunityAvatar';
import CommunityPrivacy from '@/modules/community/components/CommunityPrivacy';
import OnBoardingFields from '@/components/OnBoardingFields';
import CommunityDescription from '@/modules/community/components/CommunityDescription';

const isSettingsPage = window.onhashchange = () => {
  return window.location.pathname.includes('settings');
};

// eslint-disable-next-line react/prop-types
const avatarRender = ({value, onChange}) => {

  const {thumb} = value || {};
  return (
    <CommunityAvatar editing value={thumb} onChange={onChange} isSettingsPage={isSettingsPage()}/>
  );
};

// eslint-disable-next-line react/prop-types
const descRender = ({value, onChange}) => {
  return (
    <CommunityDescription editing value={value} onChange={onChange} isSettingsPage={isSettingsPage()}/>
  );
};


// eslint-disable-next-line react/prop-types
const colorRender = ({value, onChange}) => {
  return <CommunityColorSelect value={value} onChange={onChange}/>;
};

const customFieldsRender = () => {
  return <OnBoardingFields translation={'community'} isSettingsPage={isSettingsPage()}/>;
};

// eslint-disable-next-line react/prop-types
const privacyRender = ({value, onChange}) => {
  return <CommunityPrivacy onChange={onChange} value={value} isSettingsPage={isSettingsPage()}/>;
};

export const fields = [
  {key: 'image',
    colSpan: 2,
    label: 'community.form.image.title',
    widget: avatarRender
  },
  {
    key: 'description',
    colSpan: 2,
    label: 'community.form.description.placeholder',
    widget: descRender,
    widgetProps: {autoSize: {minRows: 5, maxRows: 12}},
  },
  {
    key: 'configurations.color',
    colSpan: 2,
    widget: colorRender,
    label: 'community.form.color.placeholder'
  },
  {
    key: 'onBoardFields',
    colSpan: 2,
    widget: customFieldsRender,
    label: 'community.form.onBoardFields.placeholder'
  },
  {
    key: 'isPrivate',
    colSpan: 2,
    widget: privacyRender
  },
  {
    key: 'createdAt',
    label: 'createdAt',
    type: 'date',
    onlyView: true,
  }
];
