import React, {useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Form} from 'antd';
import FilterContent from '@/components/TableFilters/components/FilterContent';
import classNames from 'classnames';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import {OperatorFilter, TermFilter} from '@dofleini/query-builder';

const STATE_ENUM = {
  // DELETED: 'DELETED',
  LOCK: 'LOCK',
  UNVERIFIED: 'UNVERIFIED',
  ACTIVE: 'ACTIVE',
};

const FIELDS_NAME = {
  deleted: STATE_ENUM.DELETED,
  lock: STATE_ENUM.LOCK,
  verified: STATE_ENUM.UNVERIFIED,
};

export const UserFilterStatus = ({t, applyFilter}) => {
  const options = useMemo(() => map(STATE_ENUM, value => ({value, label: `user.status.${value}`})), []);

  const onFinish = useCallback(({value: values}) => {
    if (isEmpty(values))
      return;

    const filters = map(values, value => {
      switch (value) {
        case STATE_ENUM.DELETED:
          return new TermFilter({field: 'deleted', value: true}).toQuery();
        case STATE_ENUM.LOCK:
          return new TermFilter({field: 'lock', value: true}).toQuery();
        case STATE_ENUM.UNVERIFIED:
          return new TermFilter({field: 'verified', value: false}).toQuery();
        default:
          return new OperatorFilter({
            type: 'AND', filters: [
              new TermFilter({field: 'deleted', value: false}),
              new TermFilter({field: 'lock', value: false}),
              new TermFilter({field: 'verified', value: true}),
            ]
          }).toQuery();
      }
    });

    return applyFilter({type: 'OR', filters});
  }, [applyFilter]);

  return (
    <Form onFinish={onFinish}>
      <FilterContent t={t}>
        <Form.Item name="value" className="m-0">
          <Checkbox.Group className="w-full">
            <div className="w-full flex flex-col max-h-300px">
              {
                options.map(({value, label}) => (
                  <Checkbox className={classNames('mx-0 my-px p-2')} key={value} value={value}>
                    {t(label)}
                  </Checkbox>
                ))
              }
            </div>
          </Checkbox.Group>
        </Form.Item>
      </FilterContent>
    </Form>
  );
};

UserFilterStatus.propTypes = {
  filter: PropTypes.object,
  t: PropTypes.func,
  applyFilter: PropTypes.func,
};

export const ValueUserState = ({filterValue, t}) => {
  const options = useMemo(() => map(filterValue.filters,
    ({field}) => {
      const state = FIELDS_NAME[field] || STATE_ENUM.ACTIVE;
      return <div className="px-1" key={state}>{t(`user.status.${state}`)}</div>;
    }), [t, filterValue]);

  return <div className="flex">{options}</div>;
};

ValueUserState.propTypes = {
  filter: PropTypes.object,
  t: PropTypes.func,
  filterValue: PropTypes.any,
};
