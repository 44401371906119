import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Input} from 'antd';
import styles from '../../../../assets/less/index.less';

const CommunityDescription = ({value, onChange, isSettingsPage}) => {

  return (
    <div style={{maxWidth: isSettingsPage ? '25%' : '100%'}} className={styles.settingsPageFormItem}>
      <div className={'flex justify-between items-center'}>
        <Input.TextArea value={value} onChange={onChange} autoSize={{ minRows: 3, maxRows: 5 }}/>
      </div>
    </div>
  );
};

CommunityDescription.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  isSettingsPage: PropTypes.bool
};

export default memo(CommunityDescription);

CommunityDescription.defaultProps = {
  isSettingsPage: false
};
