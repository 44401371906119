import PropTypes from 'prop-types';
import React, {memo, useMemo} from 'react';
import Text from 'antd/es/typography/Text';
import {useTranslation} from 'react-i18next';
import {Space, Switch} from 'antd';
import {useLocation} from 'react-router-dom';
import styles from '@/assets/less/index.less';

const CommunityPrivacy = ({value, onChange}) => {
  const {t} = useTranslation('community');
  const {pathname} = useLocation();

  const isSettingsPage = useMemo(() => {
    let path = pathname.split('/');

    return path[2] === 'settings';
  }, [pathname]);

  return (
    <div style={{maxWidth: isSettingsPage ? '25%' : '100%'}} className={styles.settingsPageFormItem}>
      <div className={'flex justify-between items-center'}>
        <Space direction={'vertical'}>
          <Text>{t('community.form.private.title')}</Text>
          <Text type={'secondary'}>{t('community.form.private.desc')}</Text>
        </Space>
        <Switch className={'ml-4'} checked={value} onChange={onChange}/>
      </div>
    </div>
  );
};

export default memo(CommunityPrivacy);

CommunityPrivacy.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.bool
};
