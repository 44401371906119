import {useCallback, useEffect, useState} from 'react';
import {ApiClientService} from '@dofleini/security';
import {authentication} from '@/settings';
import {message} from 'antd';


function getBase64(img, callback) {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}


const useUploadImage = (value, onChange) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImage] = useState(value);

  useEffect(()=>{
    if(value !== imageUrl)
      setImage(value);
  }, [imageUrl, value]);

  const handleChange = useCallback(info => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => {
        setImage(imageUrl);
        onChange(info.file.response);
        setLoading(false);
      }
      );
    }
  }, [onChange]);


  const props = {
    action: `${ApiClientService.API_URL.replace(/\/$/, '')}${authentication.path}/api/files/image`,
    headers: {
      Authorization: `Bearer ${ApiClientService.getToken()}`,
      ...ApiClientService.defaultHeaders
    },
  };

  return {
    props,
    handleChange,
    imageUrl,
    loading
  };

};

export default useUploadImage;
