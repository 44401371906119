import {useCallback, useMemo, useRef, useState} from 'react';
import debounce from 'lodash/debounce';

export default (defaultValue) => {
  const [value, setValue] = useState(defaultValue);
  const refValue = useRef(value);

  const search = useMemo(() => debounce(() => {
    setValue(refValue.current);
  }, 350), []);

  const handleSearch = useCallback((v) => {
    refValue.current = v;
    search();
  }, [search]);

  return [value, handleSearch];
};
