import React, {memo, useCallback, useMemo} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import {useDialogsContext} from '@/contexts/DialogsContext';
import {DIALOG_NAMESPACE} from '@/components/NewEmailDialog';
import {useUser} from '@dofleini/security';

const Email = ({children, item}) => {
  const {hasPermission} = useUser();
  const {openDialog: showEmail} = useDialogsContext(DIALOG_NAMESPACE);
  
  const isAllow = useMemo(()=> hasPermission('USER:SEND-EMAIL'), [hasPermission]);
  
  const handleClick = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
    showEmail({users: [item]});
  }, [item, showEmail]);

  if(!isAllow)
    return children;
  
  return (
    <Button type={'link'} size={'small'} className="px-0" onClick={handleClick}>{children}</Button>
  );
};

export default memo(Email);

Email.propTypes = {
  children: PropTypes.any,
  item: PropTypes.any,
};
