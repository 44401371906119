import React, {memo} from 'react';
import {ArrowLeftOutlined/*, DownOutlined*/} from '@ant-design/icons';
import {Divider/*, Dropdown, Button, Menu*/} from 'antd';
import PropTypes from 'prop-types';

// const OPTIONS = {
//   ALL: 'allNotifications'
// };

const HeaderNotification = ({t, onClose}) => {
  // const [selectedOption, setSelectedOption] = useState(OPTIONS.ALL);
  //
  // const handleMenuClick = useCallback(({key}) => {
  //   setSelectedOption(key);
  // }, []);

  // const menu = useMemo(() => (
  //   <Menu onClick={handleMenuClick}>
  //     <Menu.Item key={OPTIONS.ALL}>
  //       {t(`options.${OPTIONS.ALL}`)}
  //     </Menu.Item>
  //   </Menu>
  // ), [handleMenuClick, t]);

  return <div className="flex items-center justify-between">
    <div className="flex items-center">
      <ArrowLeftOutlined className="cursor-pointer" onClick={onClose}/>
      <Divider type="vertical" className="mx-3 h-6"/>
      <div>
        {t('title')}
      </div>
    </div>
    {/*<Dropdown overlay={menu} placement="bottomRight">*/}
    {/*  <Button type="text" className="bg-white px-0">*/}
    {/*    {t(`options.${selectedOption}`)} <DownOutlined/>*/}
    {/*  </Button>*/}
    {/*</Dropdown>*/}
  </div>;
};

HeaderNotification.propTypes = {
  t: PropTypes.func,
  onClose: PropTypes.func,
};

export default memo(HeaderNotification);