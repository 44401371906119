import React, {memo, useState, useCallback, useMemo, useEffect} from 'react';
import {useDialogsContext} from '@/contexts/DialogsContext';
import {Drawer} from 'antd';
import {useTranslation} from 'react-i18next';
import NotificationsList from '@/components/NotificationDrawer/components/NotificationsList';
import classNames from 'classnames';
import {useResponsive} from '@/contexts/ResponsiveContext';
import HeaderNotification from '@/components/NotificationDrawer/components/HeaderNotification';
// import FooterNotification from '@/components/NotificationDrawer/components/FooterNotification';
import style from './index.less';
import {useNotifications} from '@/hooks/useNotifications';
import {useCommunity} from '@/modules/community/contexts/CommunityContext';
import {CACHE_KEY_LIST_NOTIFICATIONS} from '@/constants/notifications';

export const NOTIFICATION_DRAWER = 'notification-drawer';

const NotificationDrawer = () => {

  const {t} = useTranslation('notifications');
  const {isMobile} = useResponsive();
  const {isOpen, closeDialog} = useDialogsContext(NOTIFICATION_DRAWER);

  const {currentId} = useCommunity();

  const {isLoading, data} = useNotifications(CACHE_KEY_LIST_NOTIFICATIONS, currentId);
  const [notifications, setNotifications] = useState([]);

  const transformData = useMemo(() => {
    if (data) {
      return data.map(item => {

        const body = item.body;
        delete body.name;

        if (item.body.entity === 'poll')
          item.body.entity = 'surveys';
        if (item.body.entity === 'FORUM')
          item.body.entity = 'forums';

        return   {
          read: item.viewed,
          type: item.event,
          payload: {
            body
          },
          closeDialog,
          ...item
        };
      });
    }
  }, [closeDialog, data]);

  useEffect(()=>{
    setNotifications(transformData);
  }, [transformData]);

  const updateNotification = useCallback(({id, ...rest}) => {
    setNotifications(notifications.map(notification => notification.id === id ? {...notification, ...rest} : notification));
  }, [notifications]);

  return <Drawer
    visible={isOpen}
    className={classNames(style.drawerBody, 'absolute z-30')}
    title={<HeaderNotification t={t} onClose={closeDialog}/>}
    // footer={<FooterNotification t={t} clearAll={() => null} markAsRead={() => null}/>}
    placement="left"
    onClose={closeDialog}
    getContainer={false}
    closable={false}
    width={isMobile ? '100%' : '500px'}
  >
    <NotificationsList isLoading={isLoading} notifications={transformData} updateNotification={updateNotification} />
  </Drawer>;
};

NotificationDrawer.propTypes = {};

export default memo(NotificationDrawer);
