import React, {memo, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Radio} from 'antd';
import colors from './colors';
import style from './index.less';
import Text from 'antd/es/typography/Text';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';

const CommunityColorSelect = ({value, onChange, useSort, expanded}) => {
  const {t} = useTranslation('community');
  const [more, setMore] = useState(expanded);
  
  useEffect(() => {
    setMore(expanded);
  }, [expanded]);
  
  const options = useMemo(() => {
    if (useSort)
      return colors.sort((color1) => {
        if (color1.value === value) return -1;
        return 1;
      });
    return colors;
  }, [useSort, value]);
  
  return (
    <>
      <Text className={'-mt-2 mb-2 block'} type={'secondary'}>{t('community.form.color.desc')}</Text>
      <div
        className={classNames(more ? style.expandedPickerContainer : style.pickerContainer, 'transition-all')}>
        <Radio.Group className={style.colorContainer} name="color" onChange={onChange} value={value}
          defaultValue={value || '#1890ff'}>
          {
            options.map(color =>
              <Radio
                active={color.value === value}
                key={color.value}
                value={color.value}>
                <img className={'mt-2'} src={color.render} alt={color.value}/>
              </Radio>)
          }
        </Radio.Group>
      </div>
      <Button onClick={() => setMore(!more)} type={'link'}>
        {t(`community.form.color.${more ? 'showLess' : 'showMore'}`)}</Button>
    </>
  );
};

export default memo(CommunityColorSelect);

CommunityColorSelect.propTypes = {
  onChange: PropTypes.func,
  useSort: PropTypes.bool,
  expanded: PropTypes.bool,
  value: PropTypes.string
};
