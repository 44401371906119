import {createGetOneHook} from '@/utils/createGetOneHook';
import {createFieldsHook} from '@/utils/createFieldsHook';
import CommunityApiService from '@/modules/community/services/CommunityApiService';
import {CACHE_KEY_COMMUNITY, CACHE_KEY_COMMUNITY_QR} from '@/modules/community/contants/community';
import {fields} from '@/modules/community/contants/community.form';
import {useTranslation} from 'react-i18next';
import React, {useMemo, useState} from 'react';
import CommunityName from '@/modules/community/components/CommunityName';
import {NameRuler} from '@dofleini/validator';
import {useQuery} from 'react-query';

export const useGetOneCommunity = createGetOneHook(CommunityApiService, 'id', CACHE_KEY_COMMUNITY);
export const useGetOneByNameCommunity = createGetOneHook(CommunityApiService, 'name', CACHE_KEY_COMMUNITY, CommunityApiService.getOneByName);
export const useGetQR = createGetOneHook(CommunityApiService, '_id', CACHE_KEY_COMMUNITY_QR, CommunityApiService.getQR);

export const useExistCommunity = (name) => {
  const {isLoading, isError, error, data} = useQuery(name, async () => {
    if (name) {
      const {data} = await CommunityApiService.search({search: name, size: 1});
      return (data);
    }
  });
  return {isLoading, isError, error, data};
};

const useFieldsBase = createFieldsHook('community', fields);

export const useFields = (isViewMode) => {
  const fields = useFieldsBase(isViewMode);
  const {t} = useTranslation('community');
  const [communityName, setCommunityName] = useState('');
  
  // eslint-disable-next-line react/prop-types
  const nameRender = ({value, onChange, setCommunityName}) => {
    return (
      <CommunityName editing value={value} onChange={onChange} isSettingsPage={false}
        setCommunityName={setCommunityName}/>
    );
  };

  return useMemo(() => {
    const fieldsMap = [...fields];
    if (!isViewMode) {
      fieldsMap.splice(
        1, 0,
        {
          key: 'name',
          colSpan: 2,
          label: t('community.form.name.placeholder'),
          onlyEdit: true,
          rules: [
            () => ({
              validator(rule, value) {
                if (value === '' || communityName !== value) {
                  return Promise.resolve();
                }
                return Promise.reject(t('community.form.thisCommunityAlreadyExist'));
              },
            }),
            () => ({
              validator(rule, value) {
                const pattern = new RegExp('^\\s+|\\s+$|\\s+(?=\\s)');
                if (!pattern.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(t('community.form.dontBeAllowTrailingWhitespace'));
              }
            }),
            {required: true, message: t('community.errorEmptyName'), whitespace: true},
            NameRuler
          ],
          widget: ({value, onChange}) => nameRender({value, onChange, setCommunityName})
        });

    }
    return fieldsMap;
  }, [communityName, fields, isViewMode, t]);
};
