import React, {memo} from 'react';
import {Button, Layout} from 'antd';
import {MenuOutlined} from '@ant-design/icons';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import style from './index.less';


const ResponsiveHeader = ({className, toggleSider}) => {
  return (
    <>
      <Layout.Header className={classNames('fixed w-full', className, style.header)}>
        <div>
          <Button type={'text'} size={'large'} className="text-white" onClick={toggleSider}>
            <MenuOutlined/>
          </Button>
        </div>
      </Layout.Header>
    </>
  );

};

export default memo(ResponsiveHeader);

ResponsiveHeader.propTypes = {
  className: PropTypes.string,
  toggleSider: PropTypes.func
};
