import {ApiClientService, EntityApiService} from '@dofleini/security';
import {authentication} from '@/settings';

class UserApiService extends EntityApiService {

    updateStatus = (params) => {
      return ApiClientService.post(this.getPath('/status'), params);
    };

    passwordReset = (params) => {
      return ApiClientService.post(this.getPath('/password-reset'), params);
    };

    updateUser = (params) => {
      return ApiClientService.patch(this.getPath(`/${params._id}`), params);
    };

    deleteUser = (params) => {
      return ApiClientService.delete(this.getPath(`/${params._id}`));
    };

    getStats = () => {
      return ApiClientService.get('/ms-core/api/default-reports/users-stats');
    }

}

export default new UserApiService(`${authentication.path}/api/users`);
