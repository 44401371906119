import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';
import EntityMenu from '@/components/EntityMenu';
import {
  ControlOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MailOutlined,
  RetweetOutlined
} from '@ant-design/icons';
import UserStatus from '@/modules/admin/components/UserStatus';
import {USER_STATUS} from '@/modules/admin/contants/user.status';
import {useDialogsContext} from '@/contexts/DialogsContext';
import {RESET_PASSWORD_DIALOG} from '@/modules/admin/containers/ResetPasswordModal';
import {queryCache, useMutation} from 'react-query';
import UserApiService from '@/modules/admin/services/UserApiService';
import {Button, message, Space, Tooltip} from 'antd';
import useCrud, {showConfirm} from '@/hooks/useCrud';
import {useTranslation} from 'react-i18next';
import {DIALOG_NAMESPACE} from '@/components/NewEmailDialog';
import {useHistory} from 'react-router-dom';
import {useUserList} from '@/modules/admin/containers/UserEntityContainer';
import {getUserFullName} from '@/utils/userFullName';
import PermissionCheck from '@/components/PermissionCheck';
import {SECURITY_KEY_COMMUNITY} from '@/modules/community/contants/community';
import {CACHE_KEY_STATS_USER} from '@/modules/admin/contants/users';

const options = [
  {
    icon: <EyeOutlined/>,
    title: 'details',
    action: 'details',
    
  },
  {
    icon: <EditOutlined/>,
    title: 'edit',
    action: 'edit',
    style: {minWidth: 160}
  },
  {
    divider: true,
  },
  {
    icon: <MailOutlined/>,
    title: 'sendEmail',
    action: 'sendEmail',
  },
  {
    icon: <RetweetOutlined/>,
    title: 'resetPassword',
    action: 'resetPassword',
  },
  {
    icon: <ControlOutlined/>,
    title: 'user.changeStatus',
    action: 'changeStatus',
    menu: [
      {
        title: <UserStatus status={USER_STATUS.ACTIVE} full help/>,
        action: 'changeStatus.active',
        style: {minWidth: 150}
      },
      {
        title: <UserStatus status={USER_STATUS.LOCK} full help/>,
        action: 'changeStatus.lock',
      },
    ]
  },
  {
    divider: true,
  },
  {
    icon: <DeleteOutlined/>,
    title: 'remove',
    action: 'remove',
  }
];

const useUserAction = (user) => {
  const {openDialog} = useDialogsContext(RESET_PASSWORD_DIALOG);
  const {openDialog: showEmail} = useDialogsContext(DIALOG_NAMESPACE);
  const {push} = useHistory();
  const {activeKey} = useUserList();
  const {t} = useTranslation('admin');
  const {remove} = useCrud(UserApiService, [activeKey, CACHE_KEY_STATS_USER]);
  const [mutate, {isLoading}] = useMutation(UserApiService.updateStatus, {throwOnError: true, onSuccess:()=>{
    queryCache.invalidateQueries(activeKey);
    queryCache.invalidateQueries(CACHE_KEY_STATS_USER);
  }});
  const handleChange = useCallback(async (status) => {
    try {
      await mutate({users: [user._id], status});
      message.success(t('updateStatusSuccess', {status: t(`user.status.${status}`), user: getUserFullName(user)}));
    } catch (e) {
      message.error('Error');
    }
  }, [mutate, t, user]);
  
  const handleClick = useCallback(({action}) => {
    switch (action) {
      case 'details': {
        push(`/admin/users/${user?._id}`);
        break;
      }
      case 'edit': {
        push(`/admin/users/${user?._id}/edit`);
        break;
      }
      case 'resetPassword': {
        openDialog(user);
        break;
      }
      case 'sendEmail': {
        showEmail({users: [user]});
        break;
      }
      case 'changeStatus.active': {
        handleChange(USER_STATUS.ACTIVE);
        break;
      }
      case 'changeStatus.lock': {
        handleChange(USER_STATUS.LOCK);
        break;
      }
      case 'remove': {
        showConfirm(t, user.fullName, () => remove(user._id));
        break;
      }
      default: {
        console.log('missing action handler');
      }
    }
  }, [handleChange, openDialog, push, remove, showEmail, t, user]);
  
  return {
    options,
    handleClick,
    isLoading,
    t
  };
};

const UserRowActions = ({user}) => {
  const {isLoading, options, handleClick} = useUserAction(user);
  return (
    <EntityMenu options={options} translate={'admin'} onClick={handleClick} isLoading={isLoading}/>
  );
};

// eslint-disable-next-line react/display-name
export const UserHeaderExtraActions = memo(({data}) => {
  const {isLoading, handleClick, t} = useUserAction(data);
  return (
    <Space>
      <PermissionCheck permissions={`${SECURITY_KEY_COMMUNITY}:WRITE`}>
        <Tooltip title={t('sendEmail')} key={'sendEmail'}>
          <Button
            type="text"
            disabled={isLoading}
            onClick={() => handleClick({action: 'sendEmail'})}
            icon={<MailOutlined/>}/>
        </Tooltip>
        <Tooltip title={t('resetPassword')} key={'resetPassword'}>
          <Button
            type="text"
            disabled={isLoading}
            onClick={() => handleClick({action: 'resetPassword'})}
            icon={<RetweetOutlined/>}/>
        </Tooltip>
      </PermissionCheck>
    </Space>
  );
});


export default memo(UserRowActions);

/**
 * cellRender
 * */
/**
 * This is not a component,
 * it is a function to render the Status component in a table cell
 * @param {String} id this is the value of the dataIndex of the table (we will ignored in this case)
 * @param {Object} user this is the row instance
 * */
export const renderUserActions = (id, user) => {
  return (
    <UserRowActions user={user}/>
  );
};

UserRowActions.propTypes = {
  user: PropTypes.object
};

UserHeaderExtraActions.propTypes = {
  data: PropTypes.object
};
