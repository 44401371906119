import React from 'react';
import {
  DescriptionSimpleContent
} from '@/components/NotificationDrawer/components/NotificationItem/components/SimpleContent';
import {NOTIFICATION_EVENTS} from '@/constants/notifications';
import {DescriptionForumContent} from '@/components/NotificationDrawer/components/NotificationItem/components/ForumContent';

// eslint-disable-next-line react/display-name
export default (notification, dataOwner) => {
  switch (notification?.type) {
    case NOTIFICATION_EVENTS.CONTENT_PROMOTED:
      return <DescriptionSimpleContent item={notification} dataOwner={dataOwner}/>;
    case NOTIFICATION_EVENTS.NOTICE_PUBLISHED:
      return <DescriptionSimpleContent item={notification} dataOwner={dataOwner}/>;
    case NOTIFICATION_EVENTS.FORUM_POST_REPLY:
      return <DescriptionForumContent item={notification} dataOwner={dataOwner}/>;
    default:
      return () => null;
  }
};