import {createEntityDetail} from '@/utils/createEntityDetail';
import {baseFields} from '@/modules/admin/contants/user.form';
import UserApiService from '@/modules/admin/services/UserApiService';
import {useMe} from '@/modules/authentication/hooks/useUsers';
import {createFieldsHook} from '@/utils/createFieldsHook';
import createFormFieldset from '@/utils/createFormFieldset';
import {renderFieldPassword} from '@/modules/admin/components/FieldPassword';
import pick from 'lodash/pick';

const fields = [
  ...baseFields,
  {
    key: 'security',
    colSpan: 2,
    render: createFormFieldset({title: 'user.form.security', translation: 'admin'}),
    onlyEdit: true,
  },
  {
    key: 'password',
    label: 'resetPassword',
    colSpan: 2,
    onlyEdit: true,
    rules: [{ whitespace: true }],
    widget: renderFieldPassword
  },
];

const useGetOne = () => {
  const {isLoading, data} = useMe();

  return {
    isLoading,
    data: {data}
  };
};

const mapPayload = payload => {
  const toPick = [
    'avatar',
    'firstName',
    'lastName',
    'username',
    'phone',
    'email',
  ];

  const phone = payload?.phone === '' ? null : payload?.phone;
  const email = payload?.email === '' ? null : payload?.email;

  if (payload?.password) {
    toPick.push('password');
  }

  return pick({
    ...payload,
    phone,
    email,
  }, toPick);
};

const useFields = createFieldsHook('admin', fields);

export const NAME = 'PROFILE';
export const MODULE = 'PROFILE-MODULE';

export const {Detail: UserProfile, FormComponent: ProfileDialog} = createEntityDetail({
  module: MODULE,
  getOneKey: 'user-me',
  options: {
    create: false,
    edit: true,
    details: true
  },
  service: UserApiService,
  name: NAME,
  translation: 'profile',
  translationPrefix: 'user',
  useFields: useFields,
  useGetOne,
  mapPayload
});
