import React, {memo} from 'react';
import {layout, LAYOUT_TYPE} from '@/settings';
import HorizontalLayout from '@/layout/Horizontal';
import PropTypes from 'prop-types';
import ExpandedLayout from '@/layout/TopLayout/ExpandedLayout';
import {useResponsive} from '@/contexts/ResponsiveContext';

const AppLayout = ({children, ...props}) => {
  const {isMobile} =  useResponsive();
  let Layout = HorizontalLayout;
  if (layout.type === LAYOUT_TYPE.TOP && !isMobile)
    Layout = ExpandedLayout;
  return (
    <Layout {...props}>
      {children}
    </Layout>
  );
};

export default memo(AppLayout);

AppLayout.propTypes = {
  children: PropTypes.any
};
